import { Button, Dropdown, Flex, MenuProps, Table, Tag, Tooltip, Typography, notification } from 'antd';
import { CloseCircleOutlined, CopyOutlined, DownOutlined } from '@ant-design/icons';
import { getMatchResult, getMatchResultTag } from '../utils/getMatchStatusTags';
import { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import type { TableColumnsType } from 'antd';

export default function MatchCompareTableV2(props: { runsData: any }) {
    const { runsData } = props;
    console.log("👉 ~ MatchCompareTableV2 ~ runsData:", runsData);
    const [consolidatedMatches, setConsolidatedMatches] = useState<any[]>([]);

    const [dataForTable, setDataForTable] = useState<any[]>([]);
    const [currentDatasWithFilters, setCurrentDatasWithFilters] = useState<any[]>([]);

    const prepMatchForHash = (match: any, query: any) => {
        return {
            query,
            type: match?.type,
            profileId: match?.profileId,
            location: match?.location
        }
    }

    const generateObjectHash = (obj: any): number => {
        // Sort the keys to ensure consistent ordering
        const sortedObj = Object.keys(obj).sort().reduce((acc: any, key: any) => {
            acc[key] = obj[key];
            return acc;
        }, {});

        const jsonStr: string = JSON.stringify(sortedObj);

        let hash = 0;

        if (jsonStr.length === 0) return hash;

        for (let i: number = 0; i < jsonStr.length; i++) {
            let char = jsonStr.charCodeAt(i);
            hash = ((hash << 5) - hash) + char;
            hash = hash & hash;
        }
        return hash;
    }
    const pushIfNotPresent = (arr: number[], hash: number): void => {
        if (!arr.includes(hash)) {
            arr.push(hash);
        }
    }

    useEffect(() => {
        let formattedData: any = [];

        const formatMatch = (match: any, query: any) => {
            let matchHash = generateObjectHash(prepMatchForHash(match, query));
            return {
                ...match,
                hash: matchHash
            }

        }

        runsData.forEach((run: any, index: number) => {
            if (index === 0) {
                formattedData = run.mergedData.map((record: any) => {
                    //adding hash to the match object
                    let hashesForMatches: number[] = [];

                    let processedMatches: any = [];
                    record.matches.forEach((match: any) => {
                        const formattedMatch: any = formatMatch(match, record.query);

                        pushIfNotPresent(hashesForMatches, formattedMatch.hash); //push to hashesForMatches for easing search

                        processedMatches.push(formattedMatch);
                    });

                    let formatted: any = {
                        object: record.object,
                        _index: record._index,
                        query: record.query,
                        matches: [{
                            hashesForMatches,
                            matches: processedMatches,
                            modelValidation: record.modelValidation
                        }],
                    }

                    return formatted;
                });
            }
            else {
                run.mergedData.forEach((record: any, runIndex: number) => {
                    if (formattedData[runIndex]._index === record._index) {
                        let hashesForMatches: number[] = [];

                        //adding hash to the match object
                        let processedMatches: any = [];
                        record.matches.forEach((match: any) => {
                            const formattedMatch: any = formatMatch(match, record.query);
                            pushIfNotPresent(hashesForMatches, formattedMatch.hash); //push to hashesForMatches for easing search
                            processedMatches.push(formattedMatch);
                        });

                        formattedData[runIndex].matches.push({
                            hashesForMatches,
                            matches: processedMatches,
                            modelValidation: record.modelValidation
                        });
                    }
                });
            }
        });


        setConsolidatedMatches(formattedData);
        setCurrentDatasWithFilters(formattedData);

    }, [runsData]);

    useEffect(() => {
        if (consolidatedMatches.length > 0) {
            const data: any = [...consolidatedMatches];

            data.forEach((record: any) => {
                let distinctMatchHashes: number[] = [];
                record.matches.forEach((matchGroup: any) => {
                    matchGroup.hashesForMatches.forEach((hash: number) => {
                        pushIfNotPresent(distinctMatchHashes, hash);
                    });
                });
                record.distinctMatchHashes = distinctMatchHashes;
            });

            const dataForTable: any = [];
            data.forEach((record: any) => {

                //return a row for each match present in record.distinctMatchHashes. Check in the matches array of matches if the match exists by comparing the hash. Each key of the matches array represents a run. Each run has a matches array. Each match has a hash. The hash is unique to the match and it has to match the hash of the matchWithHash object. Render a column for each run with the matchId
                if (record.distinctMatchHashes.length === 0) {
                    dataForTable.push({
                        record,
                        matchWithHash: { object: record.object }
                    });
                } else {
                    record.distinctMatchHashes.forEach((hash: number) => {
                        let matchInfos: { record: any; matchWithHash: any } | null = null as { record: any; matchWithHash: any } | null;
                        record.matches.forEach((matchGroup: any) => {
                            if (matchGroup.hashesForMatches.includes(hash)) {
                                matchInfos = {
                                    record,
                                    matchWithHash: matchGroup.matches.find((match: any) => match.hash === hash)
                                };
                            }
                        });

                        if (matchInfos !== null) {
                            dataForTable.push({
                                record: { ...matchInfos.record, matchHash: matchInfos?.matchWithHash?.hash },
                                matchWithHash: matchInfos.matchWithHash
                            });
                        }
                    });
                }

            });
            setDataForTable(dataForTable);

        }

    }, [consolidatedMatches]);

    const getDistinctCategories = (data: any): string[] => {
        let distinctCategories: string[] = [];
        data.forEach((d: any) => {

            if (!distinctCategories.includes(d.record.query.modelValidation.category)) {
                distinctCategories.push(d.record.query.modelValidation.category);
            }
        });
        return distinctCategories
    }

    const getDistinctReferences = (data: any): string[] => {
        let distinctReferences: string[] = [];
        data.forEach((d: any) => {
            if (!distinctReferences.includes(d.record.query.modelValidation.reference)) {
                distinctReferences.push(d.record.query.modelValidation.reference);
            }
        });
        return distinctReferences
    }
    const getDistinctLocationPaths = (data: any): string[] => {
        let distinctLocationPaths: string[] = [];

        data.forEach((d: any) => {
            if (d.matchWithHash.location?.path) {
                if (!distinctLocationPaths.includes(d.matchWithHash.location?.path)) {
                    distinctLocationPaths.push(d.matchWithHash.location?.path);
                }
            }

        });
        return distinctLocationPaths
    }

    const getDistinctMatchTypes = (data: any): string[] => {
        let distinctMatchTypes: string[] = [];
        data.forEach((d: any) => {
            if (d.matchWithHash.type) {
                if (!distinctMatchTypes.includes(d.matchWithHash.type)) {
                    distinctMatchTypes.push(d.matchWithHash.type);
                }
            }

        });
        return distinctMatchTypes
    }

    const getDistinctExpectedResults = (data: any): string[] => {
        let distinctExpectedResults: string[] = [];
        data.forEach((d: any) => {
            if (d.record.query.modelValidation.expected) {
                if (!distinctExpectedResults.includes(d.record.query.modelValidation.expected)) {
                    distinctExpectedResults.push(d.record.query.modelValidation.expected);
                }
            }
        });
        return distinctExpectedResults
    }



    const getColumns = (): TableColumnsType => {
        return [
            {
                title: 'Reference',
                dataIndex: 'record',
                key: 'reference',
                width: 140,
                onCell: () => {
                    return {
                        style: {
                            minWidth: 140,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                        }
                    };
                },
                render: (record: any) => {
                    return record?.query?.modelValidation?.reference;
                },
                sorter: (a: any, b: any) => {
                    return a.record.query.modelValidation.reference.localeCompare(b.record.query.modelValidation.reference);
                },
                filters: getDistinctReferences(dataForTable).map((reference: string) => {
                    return {
                        text: reference,
                        value: reference
                    }
                }),
                onFilter: (value: any, record: any) => record.record.query.modelValidation.reference === value
            },
            {
                title: 'Category',
                dataIndex: 'record',
                key: 'category',
                width: 120,
                onCell: () => {
                    return {
                        style: {
                            minWidth: 120,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                        }
                    };
                },
                render: (record: any) => {
                    return record?.query?.modelValidation?.category
                },
                sorter: (a: any, b: any) => {
                    return a.record.query.modelValidation.category.localeCompare(b.record.query.modelValidation.category);
                },
                filters: getDistinctCategories(dataForTable).map((category: string) => {
                    return {
                        text: category,
                        value: category
                    }
                }),
                onFilter: (value: any, record: any) => record.record.query.modelValidation.category === value
            },
            {
                title: 'Screened',
                dataIndex: 'matchWithHash',
                key: 'screened',
                render: (matchWithHash: any) => {
                    return matchWithHash?.screened || matchWithHash?.object
                },
                sorter: (a: any, b: any) => {
                    return a?.matchWithHash?.screened?.localeCompare(b?.matchWithHash?.screened);
                }
            },
            {
                title: 'Matched',
                dataIndex: 'matchWithHash',
                key: 'matched',
                render: (matchWithHash: any) => {
                    return matchWithHash?.match || "Failed"
                },
                sorter: (a: any, b: any) => {
                    return a?.matchWithHash?.match?.localeCompare(b?.matchWithHash?.match);
                }
            },
            {
                title: 'Expected ID',
                dataIndex: 'record',
                key: 'expectedId',
                render: (record: any) => {
                    return record?.query?.modelValidation?.profileId
                },
                sorter: (a: any, b: any) => {
                    return a?.record?.query?.modelValidation?.profileId.localeCompare(b?.record?.query?.modelValidation?.profileId);
                }
            },
            {
                title: 'Matched ID',
                dataIndex: 'matchWithHash',
                key: 'matchedId',
                render: (matchWithHash: any) => {
                    if (matchWithHash?.profileId) {
                        return <Tooltip placement="top" title={"View detailed profile in a new tab"}><Link to={`/profileDetails/${matchWithHash?.profileId}`} target="_blank" rel="noopener noreferrer">{matchWithHash?.profileId}</Link></Tooltip>
                    }
                    else {
                        return "Failed"
                    }
                },
                sorter: (a: any, b: any) => {
                    return a?.matchWithHash?.profileId?.localeCompare(b?.matchWithHash?.profileId);
                }
            },
            {
                title: 'Location Path',
                dataIndex: 'matchWithHash',
                key: 'locationPath',
                width: 160,
                render: (matchWithHash: any) => {
                    return matchWithHash?.location?.path || "Failed"
                },
                sorter: (a: any, b: any) => {
                    return a?.matchWithHash?.location?.path.localeCompare(b?.matchWithHash?.location?.path);
                },
                filters: getDistinctLocationPaths(dataForTable).map((path: string) => {
                    return {
                        text: path,
                        value: path
                    }
                }),
                onFilter: (value: any, record: any) => record.matchWithHash.location.path === value
            },
            {
                title: 'Match Type',
                dataIndex: 'matchWithHash',
                key: 'matchType',
                width: 160,
                render: (matchWithHash: any) => {
                    return matchWithHash?.type || "Failed"
                },
                sorter: (a: any, b: any) => {
                    return a?.matchWithHash?.type?.localeCompare(b?.matchWithHash?.type);
                },
                filters: getDistinctMatchTypes(dataForTable).map((type: string) => {
                    return {
                        text: type,
                        value: type
                    }
                }),
                onFilter: (value: any, record: any) => record.matchWithHash.type === value
            },
            {
                title: 'Expected Result',
                dataIndex: 'record',
                key: 'expectedResult',
                width: 180,
                render: (record: any) => {
                    return record?.query?.modelValidation?.expected.toUpperCase()
                },
                sorter: (a: any, b: any) => {
                    return a?.record?.query?.modelValidation?.expected?.localeCompare(b?.record?.query?.modelValidation?.expected);
                },
                filters: getDistinctExpectedResults(dataForTable).map((expected: string) => {
                    return {
                        text: expected,
                        value: expected
                    }
                }),
                onFilter: (value: any, record: any) => record.record.query.modelValidation.expected === value
            },
            ...runsData.map((run: any, index: number) => {
                return {
                    title: `Run ${index + 1}`,
                    dataIndex: 'record',
                    key: `runDetails${index + 1}`,
                    render: (record: any) => {
                        if (record?.matches[index]) {
                            let matchForRun: any = record?.matches[index]?.matches?.find((match: any) => match.hash === record.matchHash);
                            if (matchForRun) {
                                // const status: any = getMatchStatusTag(record?.matches[index]?.modelValidation?.status);

                                return <Flex justify='space-between' align='center'>
                                    {/* Result */}
                                    {/* <Tooltip title={getMatchResult(matchForRun, record)}> */}
                                    {getMatchResultTag(matchForRun, record)}
                                    {/* {status} */}
                                    {/* </Tooltip> */}

                                    {/* Status */}

                                    <Tooltip title="Copy query to clipboard">
                                        <Button
                                            type="link"
                                            onClick={() => {
                                                const formattedQuery = {
                                                    records: [
                                                        record?.query
                                                    ],
                                                    options: runsData[index].options
                                                };
                                                navigator.clipboard.writeText(JSON.stringify(formattedQuery))
                                                notification.success({ message: "Copied to clipboard" });
                                            }}>
                                            <CopyOutlined />
                                        </Button>
                                    </Tooltip>
                                </Flex>
                            }
                            else {
                                return <Tag color='red'><CloseCircleOutlined /> FAILED</Tag>
                                // return <Tag color='red'><CloseCircleOutlined /> NO MATCH</Tag>
                            }
                        }
                        else {
                            return <Tag color='red'><CloseCircleOutlined /> FAILED</Tag>
                            // return <Tag color='red'><CloseCircleOutlined /> NO MATCH</Tag>
                        }

                    }
                }
            })
        ]
    }

    const handleChangeOnTable = (pagination: any, filters: any, sorter: any, extra: { currentDataSource: Array<any>[] }) => {
        setCurrentDatasWithFilters(extra.currentDataSource);
    }

    const handleExportCSV = (data: any, type: 'all' | 'selection') => {

        const csv = data.map((d: any) => {

            const runResults = runsData.reduce((acc: any, run: any, index: number) => {
                if (d?.record?.matches[index]) {
                    let matchForRun: any = d?.record?.matches[index]?.matches?.find((match: any) => match.hash === d.matchWithHash.hash);
                    acc[`Run${index + 1}`] = matchForRun ? getMatchResult(matchForRun, d.record) : 'FAILED';
                } else {
                    acc[`Run${index + 1}`] = 'FAILED';
                }
                return acc;
            }, {});

            return {
                Reference: d?.record?.query?.modelValidation?.reference,
                Category: d?.record?.query?.modelValidation?.category,
                Screened: d?.record?.object,
                Matched: d?.matchWithHash?.match,
                ExpectedId: d?.record?.query?.modelValidation?.profileId,
                MatchedId: d?.matchWithHash?.profileId || '',
                LocationPath: d?.matchWithHash?.location?.path || '',
                MatchType: d?.matchWithHash?.type || '',
                ExpectedResult: d?.record?.query?.modelValidation?.expected?.toUpperCase(),
                ...runResults
            }
        });

        const now = new Date();
        const datetime = now.toISOString().replace(/:/g, '-');

        const header = Object.keys(csv[0]);
        const csvData = csv.map((row: any) => header.map(fieldName => JSON.stringify(row[fieldName])).join('\t'));
        const csvString = [header.join('\t'), ...csvData].join('\r\n');

        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `modelValidationResults_${type}_${datetime}.csv`;
        a.click();

    }

    const items: MenuProps['items'] = [
        {
            key: '0',
            label: 'Current selection (filtered and ordered)',
            onClick: () => {
                handleExportCSV(currentDatasWithFilters, 'selection');
            }
        },
        {
            key: '1',
            label: 'All matches in their original order',
            onClick: () => {
                handleExportCSV(dataForTable, 'all');
            }
        }
    ];

    return (
        <div>
            {dataForTable.length > 0 &&
                <>
                    <Flex
                        justify="space-between"
                        align="center"
                    >
                        <Typography.Title level={5}>Run Comparaison Details</Typography.Title>
                        <Dropdown menu={{ items }} placement="bottomLeft" arrow>
                            <Button type="link">Export to CSV<DownOutlined /></Button>
                        </Dropdown>

                    </Flex>
                    <Table
                        sticky={{ offsetHeader: 75 }}
                        size='small'
                        scroll={{ x: 'max-content' }}
                        dataSource={dataForTable}
                        rowKey={(item: any) => {
                            return item?.matchWidthHash?.hash;
                        }}
                        columns={getColumns()}
                        onChange={handleChangeOnTable as any}
                        pagination={{
                            showSizeChanger: dataForTable.length > 10,
                            pageSizeOptions: ['10', '20', '30', '40', '50', '100', '200', '500'],
                            defaultPageSize: 50
                        }}
                    />
                </>}
        </div>
    )
}
