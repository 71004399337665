import { Button, Descriptions, Flex, Result } from 'antd';
import { useEffect, useState } from 'react';

import LoadingSkeleton from '../Layout/LoadingSkeleton';
import Markdown from 'react-markdown';
import ReactJson from '@microlink/react-json-view';
import ShowError from '../Layout/ShowError';
import useResources from '../../hooks/useResources';

interface ShowResourceAssetsProps {
    ressourceId: string;
}

export default function ShowResourceAssets(props: ShowResourceAssetsProps) {

    const { resourcesAssets, loading, error } = useResources();

    const [assetToDisplay, setAssetToDisplay] = useState<any>(null);
    const [processedAsset, setProcessedAsset] = useState<any>(null);


    useEffect(() => {
        if (resourcesAssets && props.ressourceId) {
            const asset = resourcesAssets.find((asset: any) => {
                return asset.id === Number(props.ressourceId);
            });
            setAssetToDisplay(asset);

            const renderAssetType = async (asset: any) => {
                const pathPrefix: string = "";

                switch (asset.type) {
                    case "video":
                        return <video style={{ width: '100%', maxWidth: '1280px' }} src={pathPrefix + asset.url} controls />
                    case "image":
                        return <img style={{ width: '100%', maxWidth: '1280px' }} src={pathPrefix + asset.url} alt={asset.title} />
                    case "pdf":
                        return <embed style={{ aspectRatio: 29.7 / 21, width: '100%', maxWidth: '1280px' }} src={pathPrefix + asset.url} type="application/pdf" width="100%" />
                    case "article":
                        return <Markdown>{asset.content}</Markdown>
                    case "json":

                        const remoteJsonFile = pathPrefix + asset.url;
                        const response = await fetch(remoteJsonFile);
                        const data = await response.json();

                        return <><ReactJson
                            style={{ width: '100%', maxWidth: '1280px', border: '1px solid #e8e8e8', padding: '10px' }}
                            src={data}
                            indentWidth={2}
                            enableClipboard={false}
                            displayDataTypes={false}
                            displayObjectSize={false}
                            name={false}
                        />
                            <Button type="primary" href={remoteJsonFile}>Download JSON</Button>
                        </>
                    case 'csv':
                        return <Button type="primary" href={asset.url}>Download CSV</Button>

                    default:
                        return null;
                }
            }

            renderAssetType(asset).then((element) => {
                setProcessedAsset(element);
            }
            ).catch((e) => {
                console.log("Error while fetching asset: ", e)
            });




        }
    }, [resourcesAssets, props.ressourceId]);



    return (
        <div style={{ marginTop: '20px' }}>
            {assetToDisplay && !loading && error === null ?
                <>

                    <Flex gap="large" justify='center' align='center' vertical>

                        {processedAsset !== null && processedAsset}

                        <Descriptions title="Asset details" bordered style={{ width: '100%', maxWidth: '1280px' }}>
                            <Descriptions.Item label="Title">{assetToDisplay.title}</Descriptions.Item>
                            {assetToDisplay.description &&
                                <Descriptions.Item label="Description">{assetToDisplay.description}</Descriptions.Item>
                            }
                            <Descriptions.Item label="Type">{assetToDisplay.type}</Descriptions.Item>
                        </Descriptions>
                    </Flex>
                </>
                :
                <>
                    {loading && <LoadingSkeleton />}
                    {error && <ShowError error={error.toString()} />}
                    {resourcesAssets === null && !loading && <Result status="warning" title="There are some problems with your operation." />}
                </>
            }
        </div>
    )
}
