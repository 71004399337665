export class ApiError extends Error {
    status: number;
    constructor(message: string, status: number) {
        super(message);
        this.status = status;
    }
}

const apiRequest = async (method: string, endpoint: string, data?: any, baseUrl?: string) => {
    const url = `${baseUrl}${endpoint}`;
    const options: RequestInit = {
        method,
        headers: { "Content-Type": "application/json" },
        body: data ? JSON.stringify(data) : undefined,
    };

    const response = await fetch(url, options);
    if (!response.ok) {
        const errorMessage = await response.text();
        throw new ApiError(errorMessage, response.status);
    }
    return response.json();
}

const cache = new Map<string, Promise<any>>();

const fetchOrCache = (method: string, endpoint: string, data?: any, baseUrl?: string) => {
    const key = `${method}:${baseUrl || ''}:${endpoint}:${data ? JSON.stringify(data) : ''}`;
    if (!cache.has(key)) {
        cache.set(key, apiRequest(method, endpoint, data, baseUrl));
    }
    return cache.get(key)!;
};

export const apiHelper = {
    get: (endpoint: string, baseUrl: string) => fetchOrCache("GET", endpoint, undefined, baseUrl),
    post: (endpoint: string, data: any, baseUrl: string) => fetchOrCache("POST", endpoint, data, baseUrl),
    patch: (endpoint: string, data: any, baseUrl: string) => fetchOrCache("PATCH", endpoint, data, baseUrl),
    delete: (endpoint: string, baseUrl: string) => fetchOrCache("DELETE", endpoint, undefined, baseUrl),
};