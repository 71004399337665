import { useEffect, useState } from "react";

import { callBackEnd } from "../utils/backEndUtils";

export default function useReleaseNotes(itemId: string = '') {
    const [releaseNotes, setReleaseNotes] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<any>(null);
    

    useEffect(() => {
        async function getReleaseNotes() {
            try {
                setLoading(true);
                let content:any = await callBackEnd('portal', 'GET', `content/releaseNotes`);

                let itemsToReturn: any = [];
                if (itemId !== '') {
                    itemsToReturn = content.filter((item: any) => item.id === Number(itemId));
                    if (itemsToReturn !== undefined && itemsToReturn.length > 0) {
                        itemsToReturn = itemsToReturn[0];
                    }
                    else {
                        itemsToReturn = null;
                    }
                }
                else {
                    itemsToReturn = content;
                }
                setReleaseNotes(itemsToReturn);
                setError(null);
            } catch (error: any) {
                console.log(error);
                setError(error);
            } finally {
                setLoading(false);
            }
        }
        getReleaseNotes().catch((e: any) => console.log(e));

    }, [itemId]);

    return { releaseNotes, loading, error };
}