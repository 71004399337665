import { Button, Result } from 'antd'

import { NavLink } from 'react-router-dom'

export default function ErrorBoundaryFallback({ error }: { error: Error }) {
    return (
        <Result
            status="500"
            title="Oops! Something went wrong!"
            subTitle="An unexpected error has occurred. An is reported to the administrator."
            extra={
                <NavLink to="/">
                    <Button type="primary">Back to Home page</Button>
                </NavLink>

            }
        />
    )
}
