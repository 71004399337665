import { Alert, Button, Card, Flex, List, Typography } from 'antd';
import React, { Suspense, use } from 'react'

import { ConfigContext } from '../../contexts/ConfigContext';
import ErrorBoundary from '../ErrorBoundary';
import { Link } from 'react-router-dom';
import LoadingSkeleton from '../Layout/LoadingSkeleton';
import { ShowIfAccess } from '../../utils/secure';
import { apiHelper } from '../../utils/apiHelper';
import dayjs from 'dayjs';
import { removeMarkdownFromString } from '../../utils/stringUtils';

function NewsContent() {
    const configContext = use(ConfigContext);

    const news: any = use(apiHelper.get('content/news', configContext?.config?.portalBackEndUrl as string));
    return (
        <Card
            title="Latest News"
            extra={<Link to='/news'><Button>Read all</Button></Link>}
            className="card news-widget-card"
            bordered={false}
        >
            <Flex vertical gap={"middle"}>
                <ShowIfAccess authenticated={true} role={['crm_faq_writer']} namespaceRequired={true}>
                    <Alert message="You are viewing this page as a writer" type="success" showIcon />
                </ShowIfAccess>
                <List
                    itemLayout="vertical"
                    bordered={false}
                    size="large"
                    pagination={news?.length > 3 ? {
                        pageSize: 3,
                        align: 'center',
                    } : false}
                    dataSource={news}
                    renderItem={(item: any) => (
                        <List.Item
                            key={item.id}
                        >
                            <Link to={`/news/${item.id}`}>
                                <Typography.Title level={5} className="m-0">
                                    {item.title}
                                </Typography.Title>
                                <Typography.Paragraph ellipsis={{ rows: 3 }} className="m-0">
                                    {removeMarkdownFromString(item.content)}
                                </Typography.Paragraph>
                                <Typography.Text>{dayjs(item.dateStart).format('YYYY/MM/DD h:mm a')}</Typography.Text>
                            </Link>
                        </List.Item>
                    )}
                />
            </Flex>
        </Card>

    );

}

export default function NewsWidget() {
    return (
        <ErrorBoundary fallback={(error) => (<div>Something went wrong {error.message}</div>)}>
            <Suspense fallback={<LoadingSkeleton />}>
                <NewsContent />
            </Suspense>
        </ErrorBoundary>
    )
}
