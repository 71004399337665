import React from 'react'
import { RedocStandalone } from 'redoc'

const RenderDoc = (props:any) => {
    const {apiUrl} = props;
    const memoizedRedoc = React.useMemo(() => (
        <RedocStandalone
            specUrl={apiUrl}
            options={{  nativeScrollbars: true, 
                        scrollYOffset: 75,
                        theme: {
                            breakpoints: { medium:'1469px'},
                            colors: { 
                                primary: { main: '#82b9d6', light: '#82b9d6' } 
                            }
                        },
                        hideDownloadButton: true,
                    }}
        />
), [apiUrl])

return memoizedRedoc
}

export default RenderDoc