import { useEffect, useState } from "react";

import { callBackEnd } from "../utils/backEndUtils";
import { useRoles } from "../contexts/RolesContext";

export default function useResources() {
    const [resourcesCategories, setResourcesCategories] = useState<any>(null);
    const [resourcesAssets, setResourcesAssets] = useState<any>(null);
    const [resources, setResources] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<any>(null);

    const rolesCtx = useRoles();

    useEffect(() => {
        async function getData() {
            try {
                setLoading(true);
                const queryResourcesCategories: any = await callBackEnd('portal', 'GET', `content/resourcesCategories`).catch((e: any) => { console.log(e) });
                if (Array.isArray(queryResourcesCategories)) {
                    queryResourcesCategories.sort((a: any, b: any) => a.title.localeCompare(b.title));
                    setResourcesCategories(queryResourcesCategories);
                }

                const queryResources: any = await callBackEnd('portal', 'GET', `content/resources`).catch((e: any) => { console.log(e) });
                
                if (Array.isArray(queryResources)) {
                    setResources(queryResources);
                }

                const queryResourcesAssets: any = await callBackEnd('portal', 'GET', `content/resourcesAssets`).catch((e: any) => { console.log(e) });
                
                if (Array.isArray(queryResourcesAssets)) {
                    setResourcesAssets(queryResourcesAssets);
                }

                setError(null);
            } catch (error: any) {
                setError(error);
            } finally {
                setLoading(false);
            }
        }

        getData().catch((e: any) => { console.log(e) });

    }, [rolesCtx]);

    return { resourcesCategories, resources, loading, error, resourcesAssets };
}
