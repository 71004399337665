import { Alert, Col, Flex, Row } from 'antd';
import { HomeFilled, HomeOutlined } from '@ant-design/icons';
import { ShowIfAccess, useOidcUser } from '../utils/secure';

import { Content } from 'antd/es/layout/layout';
import { DocWidget } from './widgets/DocWidget';
import { FaqWidget } from './widgets/FaqWidget';
import { InfoWidget } from './widgets/InfoWidget';
import LoginEmail from './login/LoginEmail';
import MotionBox from './Layout/Animated/MotionBox';
import NewsWidget from './widgets/NewsWidget';
import { PageHeader } from './Layout/header/PageHeader';
import { TryApiWidget } from './widgets/TryApiWidget';
import useUserInfos from '../hooks/useUserInfos';

const Home = () => {

    const { oidcUser }: { oidcUser: any } = useOidcUser();
    const { loading: loadingUser, formattedStatus } = useUserInfos();

    return (
        <section className="homepage">
            <ShowIfAccess authenticated={true}>
                <Content className='features markdown'>
                    <PageHeader icon={<HomeFilled />}
                        title='Home'
                        withEnvSelector
                        subtitle='Portal'
                        breadcrumbs={[{ title: <span><HomeOutlined />&nbsp;Home</span>, href: '/', }]} />
                    <Content>
                        <Flex vertical gap="middle">
                            {!loadingUser && formattedStatus && formattedStatus?.display &&
                                <Row gutter={[24, 24]} >
                                    <Col xs={24} >
                                        <Alert
                                            message={formattedStatus?.message}
                                            type={formattedStatus?.type}
                                            showIcon />
                                    </Col>
                                </Row>
                            }
                            <Row gutter={[24, 24]}>
                                <Col xs={24} xl={16}>
                                    <Flex gap="large" vertical>
                                        <ShowIfAccess authenticated={true}>
                                            <MotionBox>
                                                <InfoWidget user={oidcUser?.given_name} />
                                            </MotionBox>
                                        </ShowIfAccess>
                                        <ShowIfAccess authenticated={true} module='NEWS' namespaceRequired={true}>
                                            <MotionBox>
                                                <NewsWidget />
                                            </MotionBox>
                                        </ShowIfAccess>
                                    </Flex>
                                </Col>
                                <Col xs={24} xl={8}>
                                    <Flex gap="large" vertical>
                                        <ShowIfAccess authenticated={true} module='API_DOCUMENTATION' namespaceRequired={true}>
                                            <MotionBox>
                                                <DocWidget />
                                            </MotionBox>
                                        </ShowIfAccess>
                                        <ShowIfAccess authenticated={true} role={['js_screen']}>
                                            <MotionBox>
                                                <TryApiWidget />
                                            </MotionBox>
                                        </ShowIfAccess>
                                        <ShowIfAccess authenticated={true} module='FAQ' namespaceRequired={false}>
                                            <MotionBox>
                                                <FaqWidget />
                                            </MotionBox>
                                        </ShowIfAccess>
                                    </Flex>
                                </Col>
                            </Row>
                        </Flex>
                    </Content>
                </Content>
            </ShowIfAccess>
            <ShowIfAccess authenticated={false}>
                <MotionBox>
                    <LoginEmail />
                </MotionBox>
            </ShowIfAccess>
        </section>
    );
}

export default Home;
