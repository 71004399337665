import '../../assets/scss/Faq.scss';

import { Alert, Card, Col, Collapse, Flex, Input, Row, Select, Tag, Tooltip, Typography, notification } from 'antd';
import { HomeOutlined, LinkOutlined, SearchOutlined } from '@ant-design/icons';
import { SecureWithLogin, ShowIfAccess } from "../../utils/secure";
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { Content } from 'antd/es/layout/layout';
import LoadingSkeleton from "../Layout/LoadingSkeleton";
import Markdown from 'react-markdown';
import MotionBox from "../Layout/Animated/MotionBox";
import { PageHeader } from '../Layout/header/PageHeader';
import type { SelectProps } from 'antd';
import ShowError from '../Layout/ShowError';
import { filterFaq } from './utils';
import useFaq from "../../hooks/useFaq";

export default function Faq() {
    const { faq, loading, error } = useFaq();
    const [search, setSearch] = useState<string>('');
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<string>('');
    const options: SelectProps['options'] = [];
    const location = useLocation();
    const { faqId } = useParams();

    //filters FAQ based on search ,tags, and category
    const filteredFaq = filterFaq(faq, search);
    const filteredFaqByCategory = selectedCategory !== '' ? filteredFaq.filter((item: any) => item.category.toLowerCase() === selectedCategory.toLowerCase()) : filteredFaq;
    const filteredFaqByTags = selectedTags.length > 0 ? filteredFaqByCategory.filter((item: any) => selectedTags.some((tag: string) => item.tags.includes(tag))) : filteredFaq;

    // Sort the array based on the 'category' property
    const sortedData = [...filteredFaqByTags].sort((a, b) => a?.category.localeCompare(b?.category));

    // Group the data by category
    const grouped = sortedData.reduce((acc: any, item) => {
        if (!acc[item.category]) {
            acc[item.category] = [];
        }
        acc[item.category].push(item);
        return acc;
    }, {});

    // Move 'Other' category to the end
    if (grouped['Other']) {
        const otherCategory = grouped['Other'];
        delete grouped['Other'];
        grouped['Other'] = otherCategory;
    }

    // Populate the options array with tags
    if (!loading && error === null && faq !== null && faq.length > 0 && location.pathname === '/faq') {
        filteredFaqByCategory.forEach((item: any) => {
            if (item.tags) {
                item.tags.forEach((tag: string) => {
                    if (!options.some((option) => option.value === tag)) {
                        options.push({
                            label: tag,
                            value: tag,
                        });
                    }
                });
            }
        });

        options.sort((a, b) => {
            if (a.label === 'Other') return 1;
            if (b.label === 'Other') return -1;
            return String(a.label).localeCompare(String(b.label));
        });
    }

    // Event handler for Topic Selection
    const handleSelectTopics = (selectedOptions: string[]) => {
        setSelectedTags(selectedOptions);
    };

    // Event handler for Category Selection
    const handleSelectCategory = (selectedCategory: string) => {
        setSelectedCategory(selectedCategory);
        if (selectedCategory === undefined || selectedCategory === null) {
            setSelectedCategory('');
        }

    };
    
    useEffect(() => {
        if (faqId) {
            const activePanel = document.querySelector(
                '.ant-collapse-content-active'
            );
            if (activePanel) {
                activePanel.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }

    }, [faqId, grouped]);

    const categories = Object.keys(grouped).map((category: string, key: number) => ({
        key: `faq${key}`,
        value: `${category}`,
        href: `#${category}`,
        label: `${category} (${grouped[category].length})`
    }));

    const handleCopyClick = (itemId: number) => {
        let url = window.location.href.split('?')[0];
        const urlEndsWithNumber = /\d+$/.test(url);
        if (urlEndsWithNumber) {
            url = url.replace(/\d+$/, '');
        }
        if (url.endsWith('/')) {
            url = url.slice(0, -1);
        }
        navigator.clipboard.writeText(`${url}/${itemId}`).then(() => {
            notification.success({ message: 'URL copied to clipboard' });
        }).catch(err => {
            console.error('Failed to copy URL: ', err);
        });
    }

    

    const handleSelectedTagChange = (tag: string, checked: boolean) => {
        const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter((t) => t !== tag);
        setSelectedTags(nextSelectedTags);
    };

    const getfilteredFaqs = () => {
        if (selectedCategory === '') {
            return Object.keys(grouped);
        }
        else {
            return Object.keys(grouped).filter((key: string) => key?.toLowerCase() === selectedCategory?.toLowerCase());
        }
    }

    return (
        <SecureWithLogin callbackPath="/faq" role={[]} namespaceRequired={false}>
            {/* {(!loading && error === null && faq !== null && faq.length > 0) ? */}
            <MotionBox>
                <Content className='faq'>
                    <PageHeader
                        title='Frequently Asked Questions'
                        subtitle='Portal'
                        breadcrumbs={[
                            { title: <span><HomeOutlined />&nbsp;Home</span>, href: '/', },
                            { title: <span>FAQ</span> }]}
                    />

                    {/* search and filters */}
                            <Flex vertical gap="middle">
                                <ShowIfAccess authenticated={true} role={['crm_faq_writer']} namespaceRequired={true}>
                                    <Alert message="You are viewing this page as a writer" type="success" showIcon />
                                </ShowIfAccess>
                                <Card style={{ padding: 0 }}>
                                    <Row gutter={[24, 24]}>
                                        <Col xs={24} lg={12} xxl={16}>
                                            <Input
                                                addonBefore={<SearchOutlined />}
                                                style={{ width: '100%', flex: 1 }}
                                                type="text"
                                                placeholder="Search keywords"
                                                name="search"
                                                className="search"
                                                onChange={(e: any) => setSearch(e.target.value)}
                                            />
                                        </Col>
                                        <Col xs={24} lg={6} xxl={4}>
                                            <Select
                                                showSearch
                                                allowClear
                                                placeholder="Select a Category"
                                                optionFilterProp="label"
                                                onChange={handleSelectCategory}
                                                onClear={() => setSelectedCategory('')}
                                                options={categories}
                                                style={{ minWidth: '150px', width: '100%' }}
                                            />
                                        </Col>

                                        <Col xs={24} lg={6} xxl={4}>
                                            <Select
                                                mode="multiple"
                                                allowClear
                                                style={{ minWidth: '150px', width: '100%' }}
                                                placeholder="Select Tags"
                                                onChange={handleSelectTopics}
                                                options={options}
                                                value={selectedTags}
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                            </Flex>
                        {(!loading && error === null && faq !== null && faq.length > 0) ?

                                <Card className="sectionCard">
                                    <Row gutter={[24, 24]}>
                                        {getfilteredFaqs()?.map((key, f: any, item) => {
                                            return <Col xs={24} key={key}>
                                                <Typography.Title level={4} id={key}>{key}</Typography.Title>
                                                <Collapse defaultActiveKey={faqId} collapsible='icon' items={grouped[key].map((item: any, index: number) => {
                                                    return {
                                                        label: <Flex style={{ maxWidth: '100%' }} vertical justify='space-between' className='faqTagsArrangement' key={item.id} gap={12}>
                                                            <Flex justify='space-between'>
                                                                <span>
                                                                    <Typography.Text>{item.question}<span>{' '}
                                                                        <ShowIfAccess authenticated={true} role={['crm_faq_writer']} namespaceRequired={true}>
                                                                            <Tag color={item.status === "published" ? "green" : "orange"}>{item.status}</Tag>
                                                                        </ShowIfAccess>
                                                                    </span>
                                                                        {item.tags && item.tags.length > 0 && item.tags.map((tag: string) => <Tag.CheckableTag
                                                                            key={tag + crypto.randomUUID()}
                                                                            checked={selectedTags.includes(tag)}
                                                                            className='ant-tag ant-tag-processing ant-tag-borderless css-blw3r1'
                                                                            onChange={(checked) => handleSelectedTagChange(tag, checked)}
                                                                        >
                                                                            {tag}
                                                                        </Tag.CheckableTag>)}
                                                                    </Typography.Text>
                                                                </span>
                                                                <Typography.Text style={{ marginLeft: '16px' }} copyable={{ icon: [<Tooltip title="Copy Link" ><LinkOutlined onClick={() => handleCopyClick(item.id)} /></Tooltip>, ''], tooltips: false }}></Typography.Text>
                                                            </Flex>
                                                            {/* <Flex justify='space-between'>
                                                                    <div>{item.tags && item.tags.length > 0 && item.tags.map((tag: string) => <Tag bordered={false} color="processing" key={tag} style={{marginTop:'8px'}}>{tag}</Tag>)}</div>
                                                                </Flex> */}
                                                        </Flex>,
                                                        children: <div className="faqContentMD">
                                                            <div>
                                                                <Markdown>{item.answer}</Markdown></div>
                                                        </div>
                                                        ,
                                                        key: item.id
                                                    }
                                                })}
                                                />
                                            </Col>
                                        })}
                                    </Row>
                                </Card>
                            :
                            <>
                                {loading && <LoadingSkeleton />}
                                {error && <ShowError error={error.toString()} />}
                            </>
                        }
                </Content>
            </MotionBox>

        </SecureWithLogin >
    )
}
