import { Badge, Button, Card, Col, Flex, Row, Tag, Typography } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

import { Link } from 'react-router-dom';
import Loading from '../Layout/Loading';
import { WidgetCard } from '../WidgetCard/WidgetCard';
import useAllEnvironments from '../../hooks/useAllEnvironments';
import useClusterStatus from '../../hooks/useClusterStatus';

const FullClusterStatusWidget = () => {

    const { clusterStatus: clusterStatusDev, loading: loadingDev, error: errorDev }: { clusterStatus: any, loading: boolean, error: any } = useClusterStatus('DEV');
    const { clusterStatus: clusterStatusSandbox, loading: loadingSandbox, error: errorSandbox }: { clusterStatus: any, loading: boolean, error: any } = useClusterStatus('SANDBOX');
    const { clusterStatus: clusterStatusTest, loading: loadingTest, error: errorTest }: { clusterStatus: any, loading: boolean, error: any } = useClusterStatus('TEST');
    const { clusterStatus: clusterStatusProd, loading: loadingProd, error: errorProd }: { clusterStatus: any, loading: boolean, error: any } = useClusterStatus('PROD');
    const { allEnv } = useAllEnvironments();


    const getClusterStatusFromEnv = (clusterStatus: any, loading: boolean, error: any, env: string) => {
        if (loading) return <Loading />

        const envObj: any = allEnv.find((e: any) => e.label === env);
        if (!envObj) return null;

        return (
        <Col xs={24} md={24} xl={24} xxl={24}><Card>
            <Flex justify='space-between'>
                <Typography.Title level={5}>
                    <Badge color={envObj.color} text={`${envObj.label} environment`} />
                </Typography.Title>
                <span>{clusterStatus?.message ? <Tag icon={<CheckCircleOutlined />} color="success">Operational</Tag> : <Tag icon={<CloseCircleOutlined />} color="error">Down</Tag>}</span>
            </Flex >
            <span>{clusterStatus?.apiVersion || 'Error'} - {clusterStatus?.message ? 'Service is up' : 'Service is down'}</span>
            </Card></Col>
        )
    }

    return (
        <WidgetCard className="card cluster-status-widget-card" title="Service Status" extra={<Link to="/monitoring/cluster-status"><Button >More Details</Button></Link>} bordered={false} >
            <Row gutter={[24, 24]}>
                {
                // Dev Environment
                //(errorDev) ? <Alert message="Error" description={"Unable to get the status on the Dev Environment"} type="error" showIcon /> :
                (loadingDev) ? <Loading /> : getClusterStatusFromEnv(clusterStatusDev, loadingDev, errorDev, 'Dev')
                }
                {
                // Sandbox Environment
                // (errorSandbox) ? <Alert message="Error" description={"Unable to get the status on the Sandbox Environment"} type="error" showIcon /> :
                (loadingSandbox) ? <Loading /> : getClusterStatusFromEnv(clusterStatusSandbox, loadingSandbox, errorSandbox, 'Sandbox')
                }
                {
                // Test Environment
                // (errorTest) ? <Alert message="Error" description={"Unable to get the status on the Test Environment"} type="error" showIcon /> :
                (loadingTest) ? <Loading /> : getClusterStatusFromEnv(clusterStatusTest, loadingTest, errorTest, 'Test')
                }
                {
                // Production Environment
                // (errorProd) ? <Alert message="Error" description={"Unable to get the status on the Production Environment"} type="error" showIcon /> :
                (loadingProd) ? <Loading /> : getClusterStatusFromEnv(clusterStatusProd, loadingProd, errorProd, 'Production')
                }
            </Row>
        </WidgetCard>
    )
}


export default FullClusterStatusWidget