import './../../assets/scss/GuidedTour.scss';

import { Alert, Card, Collapse, Flex, Select, Tag, Tooltip, Typography, notification } from 'antd';
import { Suspense, use, useEffect, useState } from 'react';

import { ConfigContext } from '../../contexts/ConfigContext';
import { Content } from 'antd/es/layout/layout';
import ErrorBoundary from '../ErrorBoundary';
import ErrorBoundaryFallback from '../Layout/ErrorBoundaryFallback';
import { LinkOutlined } from '@ant-design/icons';
import LoadingSkeleton from '../Layout/LoadingSkeleton';
import Markdown from 'react-markdown';
import MotionBox from '../Layout/Animated/MotionBox';
import { PageHeader } from '../Layout/header/PageHeader';
import ScreeningBlock from '../screener/ScreeningBlock';
import { ShowIfAccess } from '../../utils/secure';
import { apiHelper } from '../../utils/apiHelper';
import { useParams } from 'react-router-dom';

function GuidedTourContent({ guidedTourId }: { guidedTourId?: string }) {
    const configContext = use(ConfigContext);

    const guidedTourCategories: any = use(apiHelper.get('content/guidedTourCategories', configContext?.config?.portalBackEndUrl as string));
    const guidedTour: any = use(apiHelper.get(`content/guidedTour`, configContext?.config?.portalBackEndUrl as string));

    const [selectedCategory, setSelectedCategory] = useState<number>(0);

    const getFormattedCategories = (): string[] => {
        if (!guidedTourCategories) return [];

        let categories: any[] = [{ label: 'All', value: 0 }];
        guidedTourCategories.forEach((cat: any) => {
            categories.push({ label: cat.title, value: cat.id });
        });
        return categories;
    }

    const handleSelectCategory = (value: number) => {
        setSelectedCategory(value);
    }

    const handleCopyClick = (id: string) => {
        let url = window.location.href.split('?')[0];
        const urlEndsWithNumber = /\d+$/.test(url);
        if (urlEndsWithNumber) {
            url = url.replace(/\d+$/, '');
        }
        if (url.endsWith('/')) {
            url = url.slice(0, -1);
        }
        navigator.clipboard.writeText(`${url}/${id}`).then(() => {
            notification.success({ message: 'URL copied to clipboard' });
        }).catch(err => {
            console.error('Failed to copy URL: ', err);
        });
    }

    useEffect(() => {
        if (guidedTourId) {
            const activePanel = document.querySelector(
                '.ant-collapse-content-active'
            );
            if (activePanel) {
                activePanel.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }

    }, [guidedTourId, guidedTourCategories, guidedTour]);

    return (

        <Flex vertical gap={"middle"}>
            <ShowIfAccess authenticated={true} role={['crm_faq_writer']} namespaceRequired={true}>
                <Alert message="You are viewing this page as a writer" type="success" showIcon />
            </ShowIfAccess>

            <Flex vertical gap="large">
                <Card style={{ padding: 0 }}>
                    <Flex gap="middle">
                        <Select
                            placeholder="Select a Category"
                            // optionFilterProp="label"
                            onChange={handleSelectCategory}
                            onClear={() => setSelectedCategory(0)}
                            options={getFormattedCategories() as any}
                            style={{ minWidth: '150px', width: '100%' }}
                        />

                    </Flex>
                </Card>

                {guidedTourCategories.filter((cat: any) => {
                    if (selectedCategory === 0) return true;
                    return cat.id === selectedCategory
                }
                ).map((guidedTourCategory: any) => {
                    console.log("👉 ~ {guidedTourCategories.filter ~ guidedTourCategory:", guidedTour);

                    const tours: any[] = guidedTour?.filter((tour: any) => (tour.guidedTourCategories === guidedTourCategory.id));
                    if (tours.length === 0) return null;
                    return (
                        <Flex key={guidedTourCategory.id} vertical gap="middle">
                            <Card>
                                {guidedTourCategory.title && <Typography.Title level={4}>{guidedTourCategory.title}</Typography.Title>}
                                {guidedTourCategory.description && <div className="guidedTourCategories"><Markdown>{guidedTourCategory.description}</Markdown></div>}

                                <Collapse
                                    defaultActiveKey={guidedTourId ? [guidedTourId] : []}
                                    collapsible='icon'
                                    key={guidedTourCategory.id}
                                    //  defaultActiveKey={['1']} 
                                    items={tours.map((tour: any) => {
                                        return {
                                            key: tour.id,
                                            label: tour.title,
                                            extra: <Flex key={tour.id} gap="small">

                                                <ShowIfAccess authenticated={true} role={['crm_faq_writer']} namespaceRequired={false}>
                                                    <Tag color={tour.status === "published" ? "green" : "orange"}>{tour.status}</Tag>

                                                </ShowIfAccess>
                                                <Typography.Text
                                                    copyable={{ icon: [<Tooltip title="Copy Link" ><LinkOutlined onClick={() => handleCopyClick(tour.id)} /></Tooltip>, ''], tooltips: false }}>
                                                </Typography.Text>
                                            </Flex>,
                                            children: <div key={tour.id}>
                                                {tour?.subtitle && <Typography.Title level={5}>{tour.subtitle}</Typography.Title>}
                                                {tour.requestCode ?
                                                    <>
                                                        <ScreeningBlock
                                                            environment="DEV"
                                                            code={tour.requestCode}
                                                            url={`api/v1${tour?.url}`}
                                                            method={tour.method}
                                                        />
                                                    </>
                                                    : <Typography.Text code>{tour?.url}</Typography.Text>
                                                }

                                            </div>,
                                        }
                                    })}
                                />


                            </Card>
                        </Flex>
                    )
                })}
            </Flex>
        </Flex>
    )
}






export default function GuidedTour() {
    const { guidedTourId } = useParams();

    return (

        <>
            <ErrorBoundary fallback={(error) =>(<ErrorBoundaryFallback error={error} />)}>
                <Suspense fallback={<LoadingSkeleton />}>
                    <MotionBox>
                        <Content>
                            <PageHeader
                                title='Guided Tour'
                                subtitle='Portal'
                            />
                            {guidedTourId ? <GuidedTourContent guidedTourId={guidedTourId} /> : <GuidedTourContent />}
                        </Content>
                    </MotionBox>
                </Suspense>
            </ErrorBoundary>
        </>
    )
}
