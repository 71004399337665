import { Button, Card, Popconfirm, Table, Tag, Tooltip, notification } from "antd";
import { CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import LoadingSkeleton from "../../../Layout/LoadingSkeleton";
import { SecureWithLogin } from "../../../../utils/secure";
import StatusPills from '../../../crm/common/StatusPills';
import { callBackEnd } from "../../../../utils/backEndUtils";
import { getAsyncPackageTypes } from '../../../../utils/getPackageTypes';
import useAllEnvironments from "../../../../hooks/useAllEnvironments";
import { useRoles } from "../../../../contexts/RolesContext";

type NSAUserPackagesAssignProps = {
    readonly user: any,
    readonly namespaceId?: string
}

export default function NSAUserPackagesAssign(props: NSAUserPackagesAssignProps) {
    const { user, namespaceId } = props;
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [userPackages, setUserPackages] = useState<any[]>([]);
    const [packages, setPackages] = useState<any[]>([]);
    const { allEnv } = useAllEnvironments();
    const [refreshCounter, setRefreshCounter] = useState<number>(0);

    const rolesCtx = useRoles();
    const isRestricted: boolean = (rolesCtx?.userInfos?.owner) ? false : rolesCtx?.userInfos?.id === user?.uid; //user can't edit himself except if he's owner

    
    const [staticPackageTypes, setStaticPackageTypes] = useState<StaticPackageType[] | null>(null);

    useEffect(() => {
        async function getUserPackages() {
            try {
                setIsProcessing(true);
                const response: any = await callBackEnd('portal', 'GET', `namespaces/${namespaceId}/users/${user?.uid}/packages`);
                setUserPackages(response);
            } catch (error: any) {
                notification.error({ message: 'An error occured while loading user packages' });
                throw new Error(error);
            } finally {
                setIsProcessing(false);
            }
        }
        async function getPackages() {
            try {
                setIsProcessing(true);
                const response: any = await callBackEnd('portal', 'GET', `namespaces/${namespaceId}/packages`);
                //filter the packages that have the type=3
                const filtered:any = response.filter((p: any) => Number(p.type) !== 3);
                setPackages(filtered);
            } catch (error: any) {
                notification.error({ message: 'An error occured while loading packages' });
                throw new Error(error);
            } finally {
                setIsProcessing(false);
            }
        }
        async function getStaticPackageTypes() {
            try {

                const packageTypes: any = await getAsyncPackageTypes();
                if (packageTypes?.length > 0) {
                    setStaticPackageTypes(packageTypes);
                }
            } catch (error: any) {

                notification.error({ message: 'An error occured while loading package types' });
            }
        }
        getStaticPackageTypes().catch((error) => { console.log(error) });
        if (namespaceId && user) {
            getUserPackages().catch((e: any) => console.log(e));
            getPackages().catch((e: any) => console.log(e));
        }
    }, [namespaceId, user, refreshCounter]);

    const getNameFromStaticPackageTypes = (type: string | number) => {
        if (staticPackageTypes) {
            const t: StaticPackageType | undefined = staticPackageTypes.find((t: StaticPackageType) => t.id === type);
            if (t) {
                return t?.label.toUpperCase();
            }
            return type;
        }
        return type;
    }

    const handleRemovePackage = async (packageId: string) => {
        ///
        try {
            setIsProcessing(true);
            await callBackEnd('portal', 'DELETE', `namespaces/${namespaceId}/packages/${packageId}/users/${user.uid}`);
            console.log(`namespaces/${namespaceId}/packages/${packageId}/users/${user.uid}`);

        } catch (error: any) {
            throw new Error(error);
        } finally {
            setRefreshCounter(refreshCounter + 1);
        }
    }
    const handleAssignPackage = async (packageId: string) => {
        ///
        try {
            setIsProcessing(true);
            await callBackEnd('portal', 'POST', `namespaces/${namespaceId}/packages/${packageId}/users`, JSON.stringify({ uid: user.uid }));

        } catch (error: any) {
            throw new Error(error);
        } finally {
            setRefreshCounter(refreshCounter + 1);
        }
    }

    return (
        <SecureWithLogin callbackPath="/nsadmin" role={['nsadmin_users']} namespaceRequired={true}>
            {!isProcessing && userPackages.length > 0 && packages.length > 0 ?

                <Card
                    title="Packages"
                    type="inner"
                    extra={
                        <Link to="/nsadmin/packages">
                            <Button type="primary">View all packages</Button>
                        </Link>
                    }
                >
                    <Table
                        dataSource={packages}
                        rowKey="uid"
                        pagination={false}
                        columns={[
                            {
                                title: 'Assigned',
                                dataIndex: 'assigned',
                                key: 'assigned',
                                render: (status: string, pkg: Package) => {
                                    const isAssigned: boolean = userPackages.find((up: any) => up.uid === pkg.uid);
                                    return (
                                        <StatusPills type={isAssigned ? "enabled" : "disabled"} />
                                    )

                                },
                                filters: [
                                    { text: 'Enabled', value: "enabled" },
                                    { text: 'Disabled', value: "disabled" }
                                ],
                                onFilter: (value: any, p: any) => p.status.toLowerCase() === value,
                            },
                            {
                                title: 'Name',
                                dataIndex: 'name',
                                key: 'name',
                                render: (name: string, record: Package) => {
                                    return (
                                        <Link to={`/nsadmin/${record.namespaceId}/packages/${record.uid}`}>

                                            <Tooltip title="Go to Package details">
                                                {name}
                                            </Tooltip>

                                        </Link>
                                    )
                                }
                            },
                            {
                                title: 'Environment',
                                dataIndex: 'environment',
                                key: 'environment',
                                filters: allEnv.map((e: any) => {
                                    return { text: e.label, value: e.name }
                                }),
                                onFilter: (value: any, p: any) => p.environment === value,
                                render: (env: string) => {
                                    const color: string | undefined = allEnv.find((e: any) => e.name === env)?.color;
                                    if (color) {
                                        return <Tag color={color}>{env}</Tag>
                                    }
                                    else {
                                        return <Tag>{env}</Tag>
                                    }
                                }
                            },
                            {
                                title: 'Type',
                                dataIndex: 'type',
                                key: 'type',
                                render: (type: any) => {
                                    return <Tag color={"blue"}>{getNameFromStaticPackageTypes(type)}</Tag>
                                },
                                filters: staticPackageTypes?.map((t: StaticPackageType) => {
                                    return { text: t.label, value: t.id }
                                }),
                                onFilter: (value: any, p: any) => p.type === value,
                            },
                            {
                                title: 'Actions',
                                key: 'actions',
                                render: (text: string, pkg: any) => {
                                    const isAssigned: boolean = userPackages.find((up: any) => up.uid === pkg.uid);
                                    return (
                                        <>
                                            {Number(pkg?.type) !== 3 &&
                                            <>
                                                {isAssigned ?
                                                    <Popconfirm
                                                        title="Remove Package"
                                                        description="Are you sure to remove this package?"
                                                        onConfirm={() => { handleRemovePackage(pkg.uid) }}
                                                        onCancel={() => { }}
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <Tooltip title={isRestricted && "You can not edit your own account"}>
                                                            <Button type="dashed" danger icon={<CloseCircleOutlined />} disabled={isRestricted}>Remove Package</Button>
                                                        </Tooltip>
                                                    </Popconfirm>
                                                    :
                                                    <Popconfirm
                                                        title="Assign Package"
                                                        description="Are you sure to assign this package?"
                                                        onConfirm={() => { handleAssignPackage(pkg.uid) }}
                                                        onCancel={() => { }}
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <Tooltip title={isRestricted && "You can not edit your own account"}>
                                                            <Button type="primary" icon={<PlusCircleOutlined />} disabled={isRestricted}>Assign Package</Button>
                                                        </Tooltip>
                                                    </Popconfirm>
                                                }
                                            </>
                                            }
                                        
                                        </>
                                    )
                                }
                            }
                        ]}
                    />

                </Card>
                :
                <LoadingSkeleton />
            }


        </SecureWithLogin>
    )
}
