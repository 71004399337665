import './../../assets/scss/Resources.scss';

import { Alert, Button, Card, Flex, List, Result, Tabs } from 'antd';
import { NavLink, useParams } from 'react-router-dom';

import { Content } from 'antd/es/layout/layout';
import LoadingSkeleton from '../Layout/LoadingSkeleton';
import MotionBox from '../Layout/Animated/MotionBox';
import { PageHeader } from '../Layout/header/PageHeader';
import ShowError from '../Layout/ShowError';
import { ShowIfAccess } from '../../utils/secure';
import useResources from '../../hooks/useResources';

export default function Resources() {

    const { categoryId } = useParams();

    const { resourcesCategories, resources, loading, error } = useResources();
    const handleChangeTab = (key: string) => {
        const path: string = '/resources/category/' + key;
        // navigate(path, { replace: true });
        window.history.pushState(null, '', path);
    }

    return (
        <>
            {(!loading && error === null && resources && resourcesCategories) ?
                <MotionBox>
                    <Content>

                        <PageHeader
                            title='Resources'
                            subtitle='Portal'
                        />

                        <Flex vertical gap="large">
                            <ShowIfAccess authenticated={true} role={['crm_faq_writer']} namespaceRequired={true}>
                                <Alert message="You are viewing this page as a writer" type="success" showIcon />
                            </ShowIfAccess>
                            <Card>
                                <Tabs
                                    defaultActiveKey={categoryId ? categoryId as string : ''}
                                    onTabClick={handleChangeTab}
                                    items={resourcesCategories.map((category: any) => {
                                        const resourcesWithCat = resources.filter((resource: any) => resource.resourcesCategories === category.id);
                                        if (resourcesWithCat.length < 1) return null;
                                        return {
                                            label: category.title,
                                            key: category.id.toString(),
                                            children: (
                                                <List
                                                    bordered={true}
                                                    pagination={false}
                                                    itemLayout="horizontal"
                                                    dataSource={resources.filter((resource: any) => {
                                                        if (Number(category.id) === 0) return true;
                                                        return resource.resourcesCategories === Number(category.id)
                                                    })}
                                                    renderItem={(item: any) => (
                                                        <NavLink to={"/resources/" + item.id} key={item.id}>
                                                            <List.Item actions={[
                                                                <NavLink to={"/resources/" + item.id}>
                                                                    <Button type="link">View</Button>
                                                                </NavLink>
                                                            ]}>
                                                                <List.Item.Meta
                                                                    title={item.title}
                                                                    description={item.description}
                                                                />
                                                            </List.Item>
                                                        </NavLink>
                                                    )}
                                                />
                                            )
                                        };
                                    }).filter(Boolean)}
                                />
                            </Card>
                        </Flex>
                    </Content>
                </MotionBox>
                :
                <>
                    {loading && <LoadingSkeleton />}
                    {error && <ShowError error={error.toString()} />}
                    {resources === null && !loading && <Result status="warning" title="There are some problems with your operation." />}
                </>
            }

        </>
    )
}
