import { Flex, Typography } from 'antd';

import { Link } from 'react-router-dom';
import { ShowIfAccess } from '../../utils/secure';
import { WidgetCard } from '../WidgetCard/WidgetCard';
import { blue } from '@ant-design/colors'

export const InfoWidget = (props: any) => {
    let { user } = props;
    return (
        <WidgetCard style={{ backgroundColor: blue[3], color: 'white', backgroundImage: "url(/welcome.png)", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "right center" }}>
            <Flex vertical>
                <Typography.Title style={{ color: 'white', paddingBottom: "0" }} level={2}>Hello {user},</Typography.Title>
                <Typography.Title style={{ color: 'white', marginTop: "0" }} level={3}>Welcome to the Neterium User Portal </Typography.Title>
                <ShowIfAccess authenticated={true} role={['js_screen']}>
                    <p style={{ fontWeight: "bold" }}>
                        Come and <Link style={{ color: "white", textDecoration: "underline" }} to="/tools/try-the-api">Try the API</Link> and discover the possibilities offered by Neterium.
                    </p>
                </ShowIfAccess>
            </Flex>
        </WidgetCard>
    );
};