import './../../assets/scss/News.scss';

import { Alert, Card, Flex, Result, Space, Tag } from 'antd';
import { SecureWithLogin, ShowIfAccess } from "../../utils/secure";

import { Content } from 'antd/es/layout/layout';
import { HomeOutlined } from '@ant-design/icons';
import LoadingSkeleton from '../Layout/LoadingSkeleton';
import Markdown from 'react-markdown';
import Moment from 'react-moment';
import MotionBox from "../Layout/Animated/MotionBox";
import { PageHeader } from '../Layout/header/PageHeader';
import ShowError from '../Layout/ShowError';
import { getFormattedEnvironment } from '../../utils/getEnvironments';
import useNews from "../../hooks/useNews";
import { useParams } from 'react-router-dom';

export default function NewsPage() {
    const { newsId } = useParams();
    const { news, loading, error } = useNews(newsId);

    const showEnvPill = (env: string) => {
        if (env === 'all') return <span className={`envPill ${env}`}>All Environments</span>;

        const fomattedEnv: { name: string, label: string } | null = getFormattedEnvironment(env);

        if (!fomattedEnv) return null;

        return (
            <Tag className={`envPill ${env}`}>{fomattedEnv.label}</Tag>
        )
    }

    return (
        <SecureWithLogin callbackPath="/news" module='NEWS' role={[]} namespaceRequired={true}>
            {(!loading && error === null && news !== null) ?
                <MotionBox>
                    <Content className="news">
                        {/* <SectionBanner title="News and Updates" type="news" /> */}
                        <PageHeader
                            title='News'
                            subtitle='Portal'
                            breadcrumbs={[
                                { title: <span><HomeOutlined />&nbsp;Home</span>, href: '/', },
                                { title: <span>News</span>, href: '/news' },
                                { title: <span>{news.title}</span> }]}
                        />
                        <Flex vertical gap={"middle"} justify='center'>

                            <ShowIfAccess authenticated={true} role={['crm_faq_writer']} namespaceRequired={true}>
                                <Alert message="You are viewing this page as a writer" type="success" showIcon />
                            </ShowIfAccess>
                            <Card
                                title={news.title}
                                key={news.id}
                                extra={
                                    <Space>
                                        {news.environment.map((env: string, index: string) => {
                                            return <div key={index}>{showEnvPill(env)}</div>;
                                        })
                                        }
                                        <Moment format="YYYY/MM/DD h:mm:ss a">
                                            {news.dateStart}
                                        </Moment>
                                    </Space>
                                }
                            >

                                <Markdown className={"md"}>{news.content}</Markdown>

                            </Card>
                        </Flex>
                    </Content>
                </MotionBox>
                :
                <>
                    {loading && <LoadingSkeleton />}
                    {error && <ShowError error={error.toString()} />}
                    {news === null && !loading && <Result status="404" title="404" subTitle="Sorry, the news you are looking for does not exist." />}
                </>
            }
        </SecureWithLogin >


    )
}
