import { Flex, Typography } from 'antd';
import { Link, NavLink } from 'react-router-dom';

import { ShowIfAccess } from '../../utils/secure';
import { WidgetCard } from '../WidgetCard/WidgetCard';
import { orange } from '@ant-design/colors'
import tryApiImage from '../../assets/img/tryApi.png';

export const TryApiWidget = () => {
    return (
        <WidgetCard style={{ backgroundColor: orange[5], color: 'white', backgroundImage: "url(/welcome.png)", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "right center" }}>
            <Flex gap="large" justify='space-between' align='center'>
                <ShowIfAccess authenticated={true} role={['js_screen']}>
                    <Typography.Title style={{ color: 'white', margin: "0" }} level={3}>
                        Come and <Link style={{ color: "white", textDecoration: "underline" }} to="/tools/try-the-api">Try the API</Link> and discover the possibilities offered by Neterium.
                    </Typography.Title>
                </ShowIfAccess>
                <NavLink to="/tools/try-the-api">
                    <img src={tryApiImage} style={{width:"160px"}} alt="Try the API" />
                </NavLink>
            </Flex>
        </WidgetCard>
    );
};