import '../../assets/scss/Doc.scss';

import { SecureWithLogin, useOidcAccessToken } from '../../utils/secure';

import { Content } from 'antd/es/layout/layout';
import { EnvContext } from '../../contexts/EnvironmentContext';
import { HomeOutlined } from '@ant-design/icons';
import LoadingSkeleton from '../Layout/LoadingSkeleton';
import MotionBox from '../Layout/Animated/MotionBox';
import { PageHeader } from '../Layout/header/PageHeader';
import RenderDoc from './RenderDoc';
import ShowError from '../Layout/ShowError';
import { getBackEndURL } from '../../utils/backEndUtils';
import useClusterStatus from '../../hooks/useClusterStatus';
import { useContext } from 'react';

export default function Doc() {
    const env: string = useContext(EnvContext);
    const { clusterStatus, loading, error } = useClusterStatus(env);
    const documentationVersion: string = clusterStatus?.apiVersion || "1.7.0";
    const { accessToken } = useOidcAccessToken();

    return (
        <SecureWithLogin callbackPath="/doc" module='API_DOCUMENTATION' role={[]} namespaceRequired={true}>

            <MotionBox>
                <Content className="documentation sectionCard">
                    <PageHeader
                        withEnvSelector={true}
                        title='API Documentation'
                        subtitle='Portal'
                        breadcrumbs={[
                            { title: <span><HomeOutlined />&nbsp;Home</span>, href: '/', },
                            { title: <span>Docs</span> }]}
                    />
                    {(!loading && error === null && accessToken) ?
                        <div style={{ backgroundColor: '#fff', padding: '24px', borderRadius: "6px" }}>
                            <RenderDoc apiUrl={`${getBackEndURL('portal')}documentation/api?version=${documentationVersion}`} />
                        </div>
                        :
                        <>
                            {loading && <LoadingSkeleton />}
                            {error && <ShowError error={`Unable to get the status of the ${env.toLowerCase()} environment`} />}
                        </>
                    }
                </Content>
            </MotionBox>



        </SecureWithLogin>
    )
}
