import './../../assets/scss/Resources.scss';

import { Alert, Button, Card, Flex, Result } from 'antd';
import { NavLink, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { Content } from 'antd/es/layout/layout';
import LoadingSkeleton from '../Layout/LoadingSkeleton';
import Markdown from 'react-markdown';
import MotionBox from '../Layout/Animated/MotionBox';
import { PageHeader } from '../Layout/header/PageHeader';
import ShowError from '../Layout/ShowError';
import { ShowIfAccess } from '../../utils/secure';
import ShowResourceAssets from './ShowResourceAssets';
import useResources from '../../hooks/useResources';

export default function Resource() {
    const { resourceId } = useParams();
    const { resourcesCategories, resources, loading, error } = useResources();

    const [selectedResource, setSelectedResource] = useState<any>(null);


    useEffect(() => {
        if (resourceId && resources) {
            const selectedResource = resources.find((resource: any) => resource.id === Number(resourceId));
            setSelectedResource(selectedResource);
        }
    }, [resourceId, resources]);


    return (
        <>
            {(!loading && error === null && resources && resourcesCategories && selectedResource) ?
                <MotionBox>
                    <Content>

                        <PageHeader
                            title='Resources'
                            subtitle='Portal'
                        />
                        <Flex vertical gap="large">
                            <ShowIfAccess authenticated={true} role={['crm_faq_writer']} namespaceRequired={true}>
                                <Alert message="You are viewing this page as a writer" type="success" showIcon />
                            </ShowIfAccess>

                            <Card
                                title={selectedResource.title}
                                extra={<NavLink to={"/resources/category/" + selectedResource.resourcesCategories}>
                                    <Button type="dashed">
                                        Back to {resourcesCategories.find((cat: any) => cat.id === selectedResource.resourcesCategories)?.title}
                                    </Button>
                                </NavLink>}
                            >

                                <Markdown>{selectedResource.description}</Markdown>

                                {selectedResource.resourcesAssets.length > 0 &&
                                    <>
                                        {selectedResource.resourcesAssets.map((asset: any) => {
                                            return (
                                                <div key={asset}>
                                                    <ShowResourceAssets ressourceId={asset} />
                                                </div>
                                            )

                                        })}
                                    </>
                                }
                                <NavLink to={"/resources/category/" + selectedResource.resourcesCategories}>
                                    <Button type="dashed" style={{ marginTop: "20px" }}>
                                        Back to {resourcesCategories.find((cat: any) => cat.id === selectedResource.resourcesCategories)?.title}
                                    </Button>
                                </NavLink>
                            </Card>


                        </Flex>
                    </Content>
                </MotionBox>
                :
                <>
                    {loading && <LoadingSkeleton />}
                    {error && <ShowError error={error.toString()} />}
                    {resources === null && !loading && <Result status="warning" title="There are some problems with your operation." />}
                </>
            }

        </>
    )
}
