import { useEffect, useState } from "react";

import { callBackEnd } from "../utils/backEndUtils";
import { useRoles } from "../contexts/RolesContext";

export default function useUserGuide() {
    const [allUserGuide, setAllUserGuide] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<any>(null);

    const rolesCtx = useRoles();

    useEffect(() => {
        async function getData() {
            try {
                setLoading(true);
                const content: any = await callBackEnd('portal', 'GET', `content/userGuide`);
                setAllUserGuide(content);
                setError(null);
            } catch (error: any) {
                setError(error);
            } finally {
                setLoading(false);
            }
        }

        getData().catch((e: any) => { console.log(e) });

    }, [rolesCtx]);

    return { allUserGuide, loading, error };
}
