import '../../assets/scss/markdown/UserGuide.scss';

import { Card, Tabs, Typography } from 'antd';

import { Content } from 'antd/es/layout/layout';
import { HomeOutlined } from '@ant-design/icons';
import LoadingSkeleton from '../Layout/LoadingSkeleton';
import MotionBox from '../Layout/Animated/MotionBox';
import { PageHeader } from '../Layout/header/PageHeader';
import ReactMarkdown from 'react-markdown';
import { SecureWithLogin } from '../../utils/secure';
import ShowError from '../Layout/ShowError';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { renderers } from '../../utils/markdownUtils';
import { useParams } from 'react-router-dom';
import useUserGuide from '../../hooks/useUserGuide';

export default function UserGuide() {
    const { type } = useParams();
    const { allUserGuide, loading, error } = useUserGuide();

    // const navigate = useNavigate();
    const handleTabClick = (key: string) => {
        // navigate('/user-guide/' + key,{replace:true});
        window.history.pushState(null, '', '/documentation/user-guide/' + key);
    }


    return (
        <SecureWithLogin callbackPath="/user-guide" role={[]} namespaceRequired={true}>
            <MotionBox>
                <Content className='faq'>
                    <PageHeader
                        title='User Guide'
                        subtitle='Portal'
                        breadcrumbs={[
                            { title: <span><HomeOutlined />&nbsp;Home</span>, href: '/', },
                            { title: <span>User Guide</span> }]}
                    />
                    {(!loading && error === null && allUserGuide.length > 0) ?

                        <Card>
                            <Tabs 
                                defaultActiveKey={type ? type : ''} 
                                onTabClick={handleTabClick} 
                                items={allUserGuide?.map((guide: any) => ({
                                    label: guide.title,
                                    key: guide.id.toString(),
                                    children: (
                                        <div className="user-guide-card">
                                            <Typography.Title level={2}>{guide?.title}</Typography.Title>
                                            <ReactMarkdown components={renderers} children={guide?.content} remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} />
                                        </div>
                                    )
                                }))} 
                            />
                        </Card>

                        :
                        <>
                            {loading && <LoadingSkeleton />}
                            {error && <ShowError error={error.toString()} />}
                            {allUserGuide?.length === 0 && <ShowError error="No user guide found" />}
                        </>
                    }
                    


                </Content>
            </MotionBox>
        </SecureWithLogin>
    )
}
