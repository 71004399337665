import { Button, Card, Col, Row, Segmented } from 'antd';
import { getProductsAccess, useOidcAccessToken } from '../../utils/secure';
import { useContext, useEffect, useMemo, useState } from 'react';

import { EnvContext } from '../../contexts/EnvironmentContext';
import { Link } from 'react-router-dom';
import { ProductStatsWidget } from './ProductStatsWidget';
import moment from 'moment';
import { toTitleCase } from '../../utils/stringUtils';
import useStats from '../../hooks/useStats';

export const ProductStatsContainer = () => {
    const [product, setProduct] = useState('jetscan');
    
    const client: string = "";
    const project: string = "";
    const pack: string = "";
    const fromDate: string = moment().subtract(30, 'days').format('YYYY-MM-DD');
    const toDate: string = moment().format('YYYY-MM-DD');
    const env = useContext(EnvContext);
    const { loading, error, currentYearStats } = useStats(env, moment(fromDate).format('YYYY/MM/DD'), moment(toDate).format('YYYY/MM/DD'), client, project, product, pack, "");
    const handleProductChange = (value: string) => {
        setProduct(value);
    }

    const productsAccess = getProductsAccess(useOidcAccessToken(),env);
    // const productsAccess = ['jetflow'];
    const productsOptions: any[] = useMemo(() => [], []);

    useEffect(() => {
        
        if (productsAccess.includes('jetscan') && productsAccess.includes('jetflow')) {
            productsOptions.push({ label: <span style={(product==='jetscan')?{fontWeight:'bold',color:`var(--color${toTitleCase(product)})`}:{}}>Jetscan</span>, value: "jetscan" });
            productsOptions.push({ label: <span style={(product==='jetflow')?{fontWeight:'bold',color:`var(--color${toTitleCase(product)})`}:{}}>Jetflow</span>, value: "jetflow" });
        } else {
            if (productsAccess.includes('jetscan')) {
                setProduct('jetscan');
            } else {
                setProduct('jetflow');
            }
        }
    }, [productsAccess, product, productsOptions]);

    return (
        <Card title="Statistics" extra={
            <Link to="/monitoring/stats"><Button >View all</Button></Link>
        }>
        <Row gutter={[24, 24]}>
            {(productsOptions.length>0) && productsAccess.includes('jetscan') && productsAccess.includes('jetflow') && <Col xs={24}>
                <Segmented
                frameBorder={1}
                defaultValue={product}
                size="large"
                options={[{ label: <span style={(product==='jetscan')?{fontWeight:'bold',color:`var(--color${toTitleCase(product)})`}:{}}>Jetscan</span>, value: "jetscan" },
                { label: <span style={(product==='jetflow')?{fontWeight:'bold',color:`var(--color${toTitleCase(product)})`}:{}}>Jetflow</span>, value: "jetflow" }
                ]}
                block
                onChange={(value: string) => handleProductChange(value)}
                />
            </Col>}
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={8}>
                <ProductStatsWidget frequency="daily" data={currentYearStats} loading={loading} error={error} product={product} />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={8}>
                <ProductStatsWidget frequency="monthly" data={currentYearStats} loading={loading} error={error} product={product} />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={8}>
                <ProductStatsWidget frequency="yearly" data={currentYearStats} loading={loading} error={error} product={product} />
            </Col>
        </Row>
        </Card>
    )
}