import '../../../assets/scss/SetOptions.scss';

import { Card, Col, Collapse, Flex, Row, Select } from 'antd';
import { useEffect, useRef, useState } from "react";

import CodeMirror from "@uiw/react-codemirror";
import ShowOptions from "./../ShowOptions";
import { json } from "@codemirror/lang-json";

// import usePresets from "../../../hooks/usePresets";

export default function SetOptions(props: any) {

    //make a copy of the options to reset them if needed, keep the first options in memory

    const propOptionsCopy = useRef(props.options !== null ? { ...props.options } : null);

    const [selectedOption, setSelectedOption] = useState<{ id: number, title: string, description: string, sample: string }>();
    const [options, setOptions] = useState<any>((props.options !== null) ? props.options : {});
    const [code, setCode] = useState<string>(JSON.stringify(options, null, 4));


    const suggestedOptions: { id: number, title: string, description: string, sample: string }[] = [
        {
            id: 1,
            title: "Default",
            description: "Our engine provides default values for all options that allow for a balanced out-of-the-box screening. This configuration provides you a good starting point to then customize the screening results to your specific data (messages / list) and risk appetite.",
            sample: '{}'
        },
        {
            id: 2,
            title: "Holistic matching",
            description: `Your data does not contain only names, but includes additional datapoints providing valuable information to decide whether a match is the listed individual or a false positive.\n\nIn this case, you can look into our holistic matching settings that define your risk approach in regards to those datapoints:\n- You can set the match as "required", which would discard the match as soon as the information in the message is conflicting with the information in the listed profile.\n- For dates of birth, you can decide to use the full date or only the "year", and set a tolerance to accept a deviation(in number of years, default 0).\n- For ids, you can define the number of consecutive characters that need to match in the message against the listed profile's id(>= 3, default 5).`,
            sample: `{
    "countryPolicy": {
        "match": "required"
    },
    "cityPolicy": {
        "match": "optional"
    },
    "idPolicy": {
        "match": "optional"
    },
    "dobPolicy": {
        "match": "required"
    },
    "dobMatching": "year",
    "dobPrecision": 5,
    "idPrecision": 5
}`
        }
        ,
        {
            id: 3,
            title: "Meta-Scoring",
            description: `Your data does not contain only names, but includes additional datapoints providing valuable information to decide whether a match is the listed individual or a false positive.\n\nIn this case, you can look into our metascore feature:\n- All available data points are evaluated in a single step (names, places, gender, IDs and dates). \n- The meta score is the name score + the sum of data point scores.\n- Any matching data point has a positive impact on the final score, adding some bonus points.\n- Any mismatching data point has a negative impact on the final score, removing some malus points.\n- The default positive/negative impact for each data point can be customized via the "level" policy.\n- For dates of birth, you can decide to use the full date or only the "year", and set a tolerance to accept a deviation (in number of years, default 0).\n- For ids, you can define the number of consecutive characters that need to match in the message against the listed profile's id(>= 3, default 5).`,
            sample: `{
    "metaScore": true,
    "countryPolicy": {
        "level": "low"
    },
    "cityPolicy": {
        "level": "low"
    },
    "idPolicy": {
        "level": "low"
    },
    "dobPolicy": {
        "level": "low"
    },
    "dobMatching": "year",
    "dobPrecision": 5,
    "idPrecision": 5
}`
        },
        {
            id: 4,
            title: "Max effectiveness for name variations",
            description: `If your focus is on the effectiveness of the engine in regards to name variations, various tweaks can be enabled to maximize your outcome.\nObviously, the downside is a decreased efficiency.`,
            sample: `{
    "tweaks": {
        "longAbbreviations": true,
        "detectTitle": true,
        "partialEntityNames": true,
        "lowQualityNicknames": true,
        "detectBicCodes": true,
        "bidirectionalMatching": true
    }
}`
        },
        {
            id: 5,
            title: "Improved efficiency & Targeted Screening",
            description: 'Tuning of a screening engine is the balancing of effectiveness and efficiency in alignment with a specific risk policy.\n\nSeveral of our options allow to reduce false positives and focus on relevant matches as part of the screening process, and our Targeted Screening feature allows to apply such mechanism to specific fields and/or specific types of matches (fieldPresets, tagPresets), e.g.:\n- List scoping options allow to focus on specific sanctions, categories, statuses...\n- Minimum length for a match in a free text field can be required.\n- A higher threshold can be set for vessel matches (VSL) and/or risk country matches (LOC).\n- Vessel matches can be discarded in text fields (e.g. purpose).',
            sample: `{
    "sanctions": ["OFACSD"],
    "tweaks": {
        "minTextMatchLength": 3
    },
    "tagPresets": {
        "VSL" : {
            "threshold": 98
        },
        "LOC": {
            "threshold": 98
        }
    },
    "fieldPresets": {
        "purpose": {
            "tags": ["!VSL"]
        }
    }
}`
        }
    ]


    // const { presets } = usePresets(env);


    useEffect(() => {
        isValidJSON(JSON.stringify(options));

        // if (presets && presets.length) {
        //     let hasFoundEqual: boolean = false;
        //     for (let preset of presets) {
        //         let isEqualToPreset: boolean = JSON.stringify(preset.options) === JSON.stringify(options);
        //         if (isEqualToPreset) {

        //             inputEl.current!.value = JSON.stringify(preset.options); // 👇️ using non-null (!) assertion
        //             selectEl.current!.value = JSON.stringify(preset); // 👇️ using non-null (!) assertion
        //             hasFoundEqual = true;
        //             break;
        //         }
        //     }
        //     if (!hasFoundEqual) {
        //         selectEl.current!.value = "default";
        //     }
        // }


    }, [options]);

    const isValidJSON = (txt: string) => {
        if (txt.length > 0) {
            try {
                JSON.parse(txt);
                return true;
            } catch {
                return false;
            }
        }
        else {
            return false;
        }
    }

    const handleSetSuggestion = (id: number) => {
        if (id === 0) {
            // setSelectedOption(undefined);
            setCode(JSON.stringify(propOptionsCopy?.current, null, 4));
            setOptions(propOptionsCopy?.current);
            props.setOptions(propOptionsCopy?.current);

        } else if (isValidJSON(suggestedOptions.find((option) => option.id === id)!.sample!)) {
            setSelectedOption(suggestedOptions.find((option) => option.id === id)!);
            setCode(suggestedOptions.find((option) => option.id === id)!.sample!);
            let json: any = JSON.parse(suggestedOptions.find((option) => option.id === id)!.sample!);
            setOptions(json);
            props.setOptions(json);
        }
    }

    const handleOnChangeCode = (value: string | undefined) => {
        if (value !== undefined && isValidJSON(value)) {
            setCode(value);
            let json: any = JSON.parse(value);
            setOptions(json);
            props.setOptions(json);
        }
    }


    return (
        <>

            <div className="optionsGrid">
                <Card type="inner" extra={

                    <Select defaultValue={propOptionsCopy !== null && Object.keys(propOptionsCopy?.current).length > 0 ? 0 : null} style={{ minWidth: "250px" }} onChange={(optionId: number) => {
                        handleSetSuggestion(optionId)
                    }}
                        placeholder="Select a template"
                    >
                        {propOptionsCopy !== null && Object.keys(propOptionsCopy?.current).length > 0 &&
                            <Select.Option value={0}>Custom</Select.Option>
                        }
                        {
                            suggestedOptions?.map((option: any) => {
                                return (
                                    <Select.Option key={crypto.randomUUID() + option.id} value={option.id}>
                                        {option.title}
                                    </Select.Option>
                                )
                            })
                        }
                    </Select>
                }>
                    <div className="editorContainer">
                        <Row gutter={[16, 16]}>
                            <Col span={props.layout === 'vertical' ? 24 : 12}>
                                <CodeMirror
                                    value={code}
                                    height='400px'
                                    style={{ border: '1px solid #d9d9d9', borderRadius: '2px' }}
                                    onChange={handleOnChangeCode}
                                    extensions={[json()]}
                                />
                            </Col>
                            <Col span={props.layout === 'vertical' ? 24 : 12}>
                                <Flex gap={"small"} vertical>
                                    {selectedOption &&

                                        <Collapse
                                            defaultActiveKey={props.layout === 'vertical' ? [] : ['1']}
                                            items={
                                                [{
                                                    key: '1', label: <>{`Description: ${selectedOption?.title}`}</>, children:
                                                        <>
                                                            <p dangerouslySetInnerHTML={{ __html: selectedOption?.description.replace(/\n/g, "<br />") }}></p>
                                                        </>
                                                }]
                                            }
                                        />

                                    }
                                    <ShowOptions options={options} defaultOpen={false} />
                                </Flex>
                            </Col>
                        </Row>
                    </div>
                </Card>
            </div>
        </>
    )
}
