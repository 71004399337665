import { CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import { Tag } from 'antd';

export const getMatchStatusTag = (status: string): React.ReactElement<any> => {
    const colorAndIcon: any = () => {
        if (status === 'passed') {
            return {
                color: 'green',
                icon: <CheckCircleOutlined />
            }
        }
        else if (status === 'failed') {
            return {
                color: 'red',
                icon: <CloseCircleOutlined />
            }
        }
        else {
            return {
                color: 'orange',
                icon: <ExclamationCircleOutlined />
            }
        }
    }
    return (
        <Tag bordered={false} icon={colorAndIcon().icon} color={colorAndIcon().color}>
            {status.toUpperCase()}
        </Tag>
    )
}

export const getMatchResultTag = (m: any, d: any): React.ReactElement<any> => {
    let result: string = '';
    if (d.query.modelValidation.expected !== 'hit') {
        // result = "Unexpected Match";
        result = "Extra";
    }
    else if (d.query.modelValidation.locationPath === m.location.path && d.query.modelValidation.profileId === m.profileId) {
        // result = "Expected Match";
        // result = "Success";
        result = "Passed";
    } else if (d.query.modelValidation.locationPath !== m.location.path && d.query.modelValidation.profileId === m.profileId) {
        // result = "Extra - Other location path";
        result = "Extra";
    } else if (d.query.modelValidation.locationPath === m.location.path && d.query.modelValidation.profileId !== m.profileId) {
        // result = "Extra - Other profile id";
        result = "Extra";
    }

    const colorAndIcon: any = () => {
        // if (result === 'Expected Match') {
        // if (result === 'Success') {
        if (result === 'Passed') {
            return {
                color: 'green',
                icon: <CheckCircleOutlined />
            }
        }
        else {
            return {
                color: 'orange',
                icon: <ExclamationCircleOutlined />
            }
        }
    }
    return (
        <Tag icon={colorAndIcon().icon} color={colorAndIcon().color}>
            {result.toUpperCase()}
        </Tag>
    )
};

export const getMatchResult = (m: any, d: any): string => {
    let result: string = '';
    if (d.query.modelValidation.expected !== 'hit') {
        // result = "Unexpected Match";
        result = "Extra";
    }
    else if (d.query.modelValidation.locationPath === m.location.path && d.query.modelValidation.profileId === m.profileId) {
        // result = "Expected Match";
        result = "Passed";
    } else if (d.query.modelValidation.locationPath !== m.location.path && d.query.modelValidation.profileId === m.profileId) {
        // result = "Extra - Other location path";
        result = "Extra";
    } else if (d.query.modelValidation.locationPath === m.location.path && d.query.modelValidation.profileId !== m.profileId) {
        // result = "Extra - Other profile id";
        result = "Extra";
    }

    return result.toUpperCase()
};