import { Button, Card, Typography } from 'antd';

import { Flex } from 'antd';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import React from 'react';
import { removeMarkdownFromString } from '../../utils/stringUtils';

const { Title } = Typography;

interface NewsCardProps {
    news: {
        id: string;
        environment: string[];
        dateStart: string;
        title: string;
        image: string;
        content: string;
    };
    showEnvPill: (env: string) => React.ReactElement<any> | null;
    key: string;
}



const NewsCard: React.FC<NewsCardProps> = ({ news: n, showEnvPill }) => {
    return (
        <Card
            className='newsCard'
            key={n.id}
            title={n?.environment?.map((env: string) => { return <span key={env}>{showEnvPill(env)}</span>; })}
            extra={<Moment format="YYYY/MM/DD h:mm:ss a">{n.dateStart}</Moment>}
            style={{ height: '100%' }}
        >
            <Flex vertical key={n.id} style={{ flex:1 }}>
                <div className="newsArticle" style={{flex: 1}}>
                    <Title level={4}>{n.title}</Title>
                    <Typography.Paragraph ellipsis={{ rows: 2, expandable: false }}>
                        {removeMarkdownFromString(n.content)}
                    </Typography.Paragraph>
                </div>
                <Flex vertical align='end' ><Link to={`/news/${n.id}`}><Button type='primary'>Read More</Button></Link></Flex>
            </Flex>
        </Card>
    );
}

export default NewsCard;