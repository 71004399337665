import { Area, AreaChart, Brush, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { useEffect, useState } from "react";

import LoadingSkeleton from "../Layout/LoadingSkeleton";
import { Typography } from "antd";

export default function CustomLineChart(props: { stats: Promise<any>, product: string, title: string }) {
    const { stats, product, title } = props;
    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await stats;
                const percentiles:any[] = result[0].percentiles;
                let chartData: any = percentiles.map((s: any) => {
                    s.percent = parseInt(s.key);
                    return s;
                });
                //sort by percent
                chartData = chartData.sort((a: any, b: any) => a.percent - b.percent);
                chartData.reverse();
                
                setData(chartData);
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };

        fetchData();
    }, [stats]);

    
    return (
        <>
            {
                (data.length > 0 && !loading) ?
                <>
                    <Typography.Title level={5}>
                        {title}
                    </Typography.Title>
                    <ResponsiveContainer width="100%" height={400}>
                        <AreaChart
                            data={data}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 10,
                                bottom: 10
                            }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey={"percent"} />
                            <YAxis allowDecimals={false} />
                            <Tooltip />
                            {data.length > 1 &&
                                <Brush dataKey="name" height={20} stroke="#82b9d6" />
                            }

                            <Area type="monotone" dataKey="value" fill={product.toLowerCase() === 'jetscan' ? "#38bdf8" : '#f59e0b'} />

                        </AreaChart>
                    </ResponsiveContainer>
                </>
                :<LoadingSkeleton  />
            }
        </>
    )
}
