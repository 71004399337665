import { Button, Drawer, Dropdown, Flex, Space, Table, Tag, Tooltip, Typography, notification } from 'antd';
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    DownOutlined,
    ExclamationCircleOutlined,
    EyeTwoTone,
    MoreOutlined
} from '@ant-design/icons';
import type { MenuProps, TableColumnsType } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

const { Column } = Table;


export default function ResultTableFlattened(props: Readonly<{ mergedData: any[], options: any, context: any }>) {
    interface ResultTableData {
        reference: string,
        category: string,
        screening: string,
        profileId: string,
        score: number,
        type: string,
        expected: string,
        status: string,
        screened: string,
        result: string
    }

    const mergedData = props.mergedData;

    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const [drawerContent, setDrawerContent] = useState<any>(null);

    const showDrawer = (value: any) => {
        setDrawerContent(value);
        setOpenDrawer(true);
    };

    const onClose = () => {
        setDrawerContent(null);
        setOpenDrawer(false);
    };

    const getDistinctReferences = (data: any[]) => {
        const references = new Set();

        data.forEach((d: any) => {
            if (d.modelValidation && d.modelValidation.reference) {
                references.add(d.modelValidation.reference);
            }
        });

        return Array.from(references);
    }
    const getDistinctCategories = (data: any[]) => {
        const categories = new Set();

        data.forEach((d: any) => {
            if (d?.query?.modelValidation?.category) {
                categories.add(d.query.modelValidation.category);
            }
        });

        return Array.from(categories);
    }
    const getDisticntScreened = (data: any[]) => {
        const screened = new Set();

        data.forEach((d: any) => {
            if (d.object) {
                screened.add(d.object);
            }
        });

        return Array.from(screened);
    }
    const getDistinctProfileIds = (data: any[]) => {
        const profileIds = new Set();

        data.forEach((d: any) => {
            if (d.query.modelValidation.profileId) {
                profileIds.add(d.query.modelValidation.profileId);
            }
        });

        return Array.from(profileIds);
    }
    const getDistinctMatchTypes = (data: any[]) => {
        const matchTypes = new Set();

        data.forEach((d: any) => {
            if (d.matches) {
                d.matches.forEach((m: any) => {
                    if (m.type) {
                        matchTypes.add(m.type);
                    }
                });
            }
        });

        return Array.from(matchTypes);
    }

    const columns: TableColumnsType<ResultTableData> = [
        {
            title: 'Reference',
            dataIndex: 'reference',
            width: 140,
            onCell: () => {
                return {
                    style: {
                        minWidth: 140,
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    }
                };
            },
            filters: getDistinctReferences(mergedData).map((reference: any) => { return { text: reference, value: reference } }),
            onFilter: (value: any, record: any) => record.reference === value,
            filterSearch: true,
            sorter: (a, b) => a.reference.localeCompare(b.reference),
        },
        {
            title: 'Category',
            dataIndex: 'category',
            width: 120,
            onCell: () => {
                return {
                    style: {
                        minWidth: 120,
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    }
                };
            },
            filters: getDistinctCategories(mergedData).map((category: any) => { return { text: category, value: category } }),
            onFilter: (value: any, record: any) => record.category === value,
            filterSearch: true,
            sorter: (a, b) => a.category.localeCompare(b.category),
        },
        {
            title: 'Screened',
            dataIndex: 'screened',
            sorter: (a, b) => a.screened.localeCompare(b.screened),
            filters: getDisticntScreened(mergedData).map((screened: any) => { return { text: screened, value: screened } }),
            onFilter: (value: any, record: any) => record.screened === value,
            filterSearch: true,
        },
        {
            title: 'Expected Profile ID',
            dataIndex: 'profileId',
            render: (text: string) => <Tooltip placement="top" title={"View detailed profile in a new tab"}><Link to={`/profileDetails/${text}`} target="_blank" rel="noopener noreferrer">{text}</Link></Tooltip>,
            sorter: (a, b) => a.profileId.localeCompare(b.profileId),
            filters: getDistinctProfileIds(mergedData).map((profileId: any) => { return { text: profileId, value: profileId } }),
            onFilter: (value: any, record: any) => record.profileId === value,
            filterSearch: true,
        },
        {
            title: 'Match Type',
            dataIndex: 'type',
            sorter: (a, b) => a.type.localeCompare(b.type),
            filters: getDistinctMatchTypes(mergedData).map((type: any) => { return { text: type, value: type } }),
            onFilter: (value: any, record: any) => record.type === value,
            filterSearch: true,
            render: (text: string) => { return (text) ? text.toUpperCase() : 'N/A' },
        },
        {
            title: 'Score',
            dataIndex: 'score',
            sorter: (a, b) => a.score - b.score,
            width: 80,
        },
        {
            title: 'Expected',
            dataIndex: 'expected',
            filters: [
                {
                    text: 'Hit',
                    value: 'hit',
                },
                {
                    text: 'No Hit',
                    value: 'nohit',
                }
            ],
            onFilter: (value: any, record: any) => record.expected === value,
            filterSearch: true,
            render: (text: string) => { return text.toUpperCase() },
            width: 100,
        },
        {
            title: 'Result',
            dataIndex: 'result',
            sorter: (a, b) => a.result.localeCompare(b.result),
            filters: [
                {
                    text: 'Expected Match',
                    value: 'Expected Match',
                },
                {
                    text: 'Extra - Other location path',
                    value: 'Extra - Other location path',
                },
                {
                    text: 'Extra - Other profile id',
                    value: 'Extra - Other profile id',
                },
                {
                    text: 'No Match Found',
                    value: 'No Match Found',
                },
                {
                    text: 'Extra - No match expected',
                    value: 'Extra - No match expected',
                },
                {
                    text: 'Unexpected Match',
                    value: 'Unexpected Match',
                }
            ],
            onFilter: (value: any, record: any) => record.result === value,
            filterSearch: true,
            render: (result: string) => {
                const colorAndIcon: any = () => {
                    if (result === 'Expected Match') {
                        return {
                            color: 'green',
                            icon: <CheckCircleOutlined />
                        }
                    }
                    else {
                        return {
                            color: 'orange',
                            icon: <ExclamationCircleOutlined />
                        }
                    }
                }
                return (
                    <Tag bordered={false} icon={colorAndIcon().icon} color={colorAndIcon().color}>
                        {result.toUpperCase()}
                    </Tag>
                )
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            filters: [
                {
                    text: 'Passed',
                    value: 'passed',
                },
                {
                    text: 'Failed',
                    value: 'failed',
                },
                {
                    text: 'Extra',
                    value: 'extra',
                }
            ],
            onFilter: (value: any, record: any) => record.status === value,
            filterSearch: true,
            render: (status: string) => {
                const colorAndIcon: any = () => {
                    if (status === 'passed') {
                        return {
                            color: 'green',
                            icon: <CheckCircleOutlined />
                        }
                    }
                    else if (status === 'failed') {
                        return {
                            color: 'red',
                            icon: <CloseCircleOutlined />
                        }
                    }
                    else {
                        return {
                            color: 'orange',
                            icon: <ExclamationCircleOutlined />
                        }
                    }
                }
                return (
                    <Tag bordered={false} icon={colorAndIcon().icon} color={colorAndIcon().color}>
                        {status.toUpperCase()}
                    </Tag>
                )
            }
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'x',
            // render: (value: any) => value.match !== null && <Tooltip title="View match details" placement="left"><Button type="link" onClick={() => showDrawer(value)}><EyeTwoTone /></Button></Tooltip>,
            render: (value: any) => {
                const items: MenuProps['items'] = [
                    {
                        key: '0',
                        label: <Space size="small"><EyeTwoTone />View request & match details</Space>,
                        onClick: () => showDrawer(value),
                    }, {
                        key: '1',
                        label: <Space size="small"><FontAwesomeIcon icon={faClipboard} />Copy to clipboard</Space>,
                        onClick: () => {
                            const toExport: { records: any[], options: any, context: any } = {
                                records: [value.processedData.query],
                                options: props.options,
                                context: props.context

                            };
                            navigator.clipboard.writeText(JSON.stringify(toExport))
                            notification.success({ message: "JSON copied to clipboard!" });
                        }
                    }
                ];
                return (

                    <Dropdown menu={{ items }}>
                        <Button type="link">More<MoreOutlined /></Button>
                    </Dropdown>

                )
            }
        }
    ]

    const getMatchResult = (m: any, d: any) => {
        let result: string = '';
        if (d.query.modelValidation.expected !== 'hit') {
            result = "Unexpected Match";
        }
        else if (d.query.modelValidation.locationPath === m.location.path && d.query.modelValidation.profileId === m.profileId) {
            result = "Expected Match";
        } else if (d.query.modelValidation.locationPath !== m.location.path && d.query.modelValidation.profileId === m.profileId) {
            result = "Extra - Other location path";
        } else if (d.query.modelValidation.locationPath === m.location.path && d.query.modelValidation.profileId !== m.profileId) {
            result = "Extra - Other profile id";
        }
        return result;
    }

    const processDataForTable = (data: any) => {
        let processedData: any = [];

        data.forEach((d: any) => {

            const getScreenedField = (d: any) => {
                if (d?.object) {
                    return d.object; //JETSCAN
                }
                else if (d?.query?.type === 'transaction') { //Jetflow
                    if (d?.query?.modelValidation?.locationPath === '/creditor/name') { //creditor
                        return d?.query?.object?.creditor?.name;
                    }
                    else if (d?.query?.modelValidation?.locationPath === '/debtor/name') { //debtor
                        return d?.query?.object?.debtor?.name;
                    }
                    else if (d?.query?.modelValidation?.locationPath.includes('intermediaries')) { //  /intermediaries
                        const regex = /\[(.*?)\]/g;
                        const intermediariesIndex: string[] | null = regex.exec(d?.query?.modelValidation?.locationPath);
                        if (intermediariesIndex && intermediariesIndex.length > 0) {
                            return d?.query?.object?.intermediaries[intermediariesIndex[0]]?.name;
                        }
                        else {
                            return 'No data';
                        }
                    }

                    function getValueByXPath(obj: any, xpath: string) {

                        // Split the XPath into parts by "/"
                        const keys = xpath.split('/');

                        // Traverse the object based on the keys
                        let currentValue = obj;
                        for (const key of keys) {
                            // Check if the key is referencing an array with an index (e.g., "array[0]")
                            const arrayMatch = key.match(/^([a-zA-Z0-9_]+)\[(\d+)\]$/);
                            if (arrayMatch) {
                                // Key is an array, extract the array name and index
                                const arrayKey = arrayMatch[1];
                                const index = parseInt(arrayMatch[2], 10);

                                if (currentValue[arrayKey] && Array.isArray(currentValue[arrayKey])) {
                                    currentValue = currentValue[arrayKey][index];
                                } else {
                                    return "No data";
                                }
                            } else {
                                // Key is a regular object property
                                if (currentValue && currentValue.hasOwnProperty(key)) {
                                    currentValue = currentValue[key];
                                } else {
                                    return "No data";
                                }
                            }
                        }

                        return currentValue;
                    }

                    return getValueByXPath(data, d?.query?.modelValidation?.locationPath);
                }
                return 'No data';
            }

            if (d.matches.length > 0) { //HAS MATCHES
                d.matches.forEach((m: any) => {



                    processedData.push({
                        reference: d.modelValidation.reference,
                        category: d.query.modelValidation.category,
                        // screened: `${m.screened}`,
                        // screened: `${d.object}`,
                        screened: getScreenedField(d),
                        // profileId: `${m.profileId}`,
                        profileId: `${d.query.modelValidation.profileId}`,
                        type: m.type,
                        score: m.score,
                        result: getMatchResult(m, d),
                        expected: d.query.modelValidation.expected,
                        status: getMatchResult(m, d).includes('Extra') ? "extra" : d.modelValidation.status,
                        action: { processedData: d, match: m }
                    });
                })
            }
            else { //NO MATCHES
                processedData.push({
                    reference: d.modelValidation.reference,
                    category: d.query.modelValidation.category,
                    // screened: d.object,
                    screened: getScreenedField(d),
                    profileId: d.query.modelValidation.profileId,
                    type: '',
                    score: (d.query.modelValidation.expected === 'hit') ? '0' : '100',
                    result: (d.query.modelValidation.expected === 'hit') ? 'No Match Found' : 'Extra - No match expected',
                    expected: d.query.modelValidation.expected,
                    status: d.modelValidation.status,
                    action: { processedData: d, match: null }
                });
            }
        }
        )
        return processedData;
    }

    const getTestPlaces = (metadata: any) => {
        let places: any[] = [];
        if (metadata.hasOwnProperty("places")) {
            metadata["places"].forEach((addr: any) => places.push(
                (addr.hasOwnProperty("city") ? addr["city"] + "/" : "")
                + (addr.hasOwnProperty("country") ? addr["country"] : "")
                + (addr.hasOwnProperty("type") ? " (" + addr["type"] + ")" : "")
            ))
        }
        return places.length !== 0 ? places.join(", ") : "";
    }

    const getTestDobs = (metadata: any) => {
        let dobs: any[] = [];
        if (metadata.hasOwnProperty("dates")) {
            metadata["dates"].forEach((dob: any) => dobs.push(
                (dob.hasOwnProperty["year"] ? String(dob["year"]) : "")
                + (dob.hasOwnProperty["month"] ? "/" + String(dob["month"]) : "")
                + (dob.hasOwnProperty["day"] ? "/" + String(dob["day"]) : "")
            ))
        }
        return dobs.length ? dobs.join(", ") : "";
    }

    const getMatchPlaces = (match: any) => {
        let places: any[] = [];
        if (match["profileSummary"].hasOwnProperty("birth") && match["profileSummary"]["birth"].hasOwnProperty("places")) {
            match["profileSummary"]["birth"]["places"].forEach((pob: any) => places.push(
                (pob.hasOwnProperty("city") ? pob["city"] + "/" : "")
                + (pob.hasOwnProperty("country") ? pob["country"] + " (pob)" : "")
            ))
        }
        if (match["profileSummary"].hasOwnProperty("addresses")) {
            match["profileSummary"]["addresses"].forEach((addr: any) => places.push(
                (addr.hasOwnProperty("city") ? addr["city"] + "/" : "")
                + (addr.hasOwnProperty("country") ? addr["country"] + " (address)" : "")
            ))
        }
        if (match["profileSummary"].hasOwnProperty("countries")) {
            match["profileSummary"]["countries"].forEach((ctry: any) => places.push(
                ctry + " (other)"
            ))
        }
        return places.length !== 0 ? places.join(", ") : ""
    }

    const getMatchDobs = (match: any) => {
        let dobs: any[] = [];
        if (match["profileSummary"].hasOwnProperty("birth") && match["profileSummary"]["birth"].hasOwnProperty("dates")) {
            match["profileSummary"]["birth"]["dates"].forEach((dob: any) => dobs.push(
                (dob.hasOwnProperty["year"] ? String(dob["year"]) : "")
                + (dob.hasOwnProperty["month"] ? "/" + String(dob["month"]) : "")
                + (dob.hasOwnProperty["day"] ? "/" + String(dob["day"]) : "")
            ))
        }
        return dobs.length ? dobs.join(", ") : ""
    }

    const getTestIds = (metadata: any) => {
        return metadata.hasOwnProperty("ids") ? metadata["ids"].join(", ") : ""
    }
    const getMatchIds = (match: any) => {
        return match["profileSummary"].hasOwnProperty("ids") ? match["profileSummary"]["ids"].join(", ") : ""
    }

    const getTestGender = (metadata: any) => {
        return metadata.hasOwnProperty("gender") ? metadata["gender"] : ""
    }
    const getMatchGender = (match: any) => {
        return match["profileSummary"].hasOwnProperty("gender") ? match["profileSummary"]["gender"] : ""
    }

    const processDrawerData: any = (data: any) => {


        const formatted: any = [
            {
                category: "Name",
                request: data.processedData.object,
                match: data.match !== null ? data?.match?.profileSummary?.name !== data.match.match ? data.match.match + " (aka)" : data?.match?.profileSummary?.name : "No match found"
            },
            {
                category: "Location Path",
                request: data.processedData.query.modelValidation.locationPath,
                match: data.match !== null ? data.match.location.path : "No match found"
            },
            {
                category: "Profile ID",
                request: data.processedData.query.modelValidation.profileId,
                match: data.match !== null ? data.match.profileId : "No match found"
            },
            {
                category: "Places",
                request: data?.processedData.query?.modelValidation?.metadata && getTestPlaces(data?.processedData.query?.modelValidation?.metadata),
                match: data.match !== null ? getMatchPlaces(data.match) : "No match found"
            },
            {
                category: "Dates of birth",
                request: data?.processedData.query?.modelValidation?.metadata && getTestDobs(data?.processedData.query?.modelValidation?.metadata),
                match: data.match !== null ? getMatchDobs(data.match) : "No match found"
            },
            {
                category: "IDs",
                request: data?.processedData.query?.modelValidation?.metadata && getTestIds(data?.processedData.query?.modelValidation?.metadata),
                match: data.match !== null ? getMatchIds(data.match) : "No match found"
            },
            {
                category: "Gender",
                request: data?.processedData.query?.modelValidation?.metadata && getTestGender(data?.processedData.query?.modelValidation?.metadata),
                match: data.match !== null ? getMatchGender(data.match) : "No match found"
            }
        ]

        return formatted;
    }

    const handleChangeOnTable = (pagination: any, filters: any, sorter: any, extra: { currentDataSource: Array<any>[] }) => {
        setCurrentDatasWithFilters(extra.currentDataSource);
    }

    const handleExportCSV = (data: any, type: 'all' | 'selection') => {
        const csv = data.map((d: any) => {

            return {
                Reference: d.reference,
                Category: d.category,
                Screened: d.screened,
                ProfileID: d.profileId,
                MatchType: d.type,
                Places: d.action.match ? getMatchPlaces(d.action.match) : '',
                DOBs: d.action.match ? getMatchDobs(d.action.match) : '',
                IDs: d.action.match ? getMatchIds(d.action.match) : '',
                Score: d.score,
                Expected: d.expected,
                Result: d.result,
                Status: d.status,
                QueryJSON: JSON.stringify(d.action.processedData.query),
            }
        });

        const now = new Date();
        const datetime = now.toISOString().replace(/:/g, '-');

        const header = Object.keys(csv[0]);
        const csvData = csv.map((row: any) => header.map(fieldName => JSON.stringify(row[fieldName])).join('\t'));
        const csvString = [header.join('\t'), ...csvData].join('\r\n');

        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `modelValidationResults_${type}_${datetime}.csv`;
        a.click();

    }

    const [currentDatasWithFilters, setCurrentDatasWithFilters] = useState<any[]>(processDataForTable(mergedData));
    const items: MenuProps['items'] = [
        {
            key: '0',
            label: 'Current selection (filtered and ordered)',
            onClick: () => {
                handleExportCSV(currentDatasWithFilters, 'selection');
            }
        },
        {
            key: '1',
            label: 'All matches in their original order',
            onClick: () => {
                handleExportCSV(processDataForTable(mergedData), 'all');
            }
        }
    ];

    return (
        <div className="resultTableContainer">
            <Flex
                justify="space-between"
                align="center"
            >
                <Typography.Title level={5}>
                    Detailed matches
                </Typography.Title>
                <Dropdown menu={{ items }} placement="bottomLeft" arrow>
                    <Button type="link">Export to CSV<DownOutlined /></Button>
                </Dropdown>
            </Flex>
            <Table
                sticky={{ offsetHeader: 75 }}
                columns={columns}
                dataSource={processDataForTable(mergedData)}
                size="small"
                onChange={handleChangeOnTable as any}
                scroll={{ x: 'max-content' }}
                rowKey={(record: any) => record.profileId + crypto.randomUUID()}
                pagination={{
                    showSizeChanger: processDataForTable(mergedData).length > 10,
                    pageSizeOptions: ['10', '20', '30', '40', '50'],
                    defaultPageSize: 20
                }}
            />
            {drawerContent &&
                <Drawer title="Details" onClose={onClose} open={openDrawer} size="large" zIndex={1010}>
                    <Flex vertical gap={"middle"}>
                        <Table pagination={false} dataSource={processDrawerData(drawerContent)} scroll={{ x: 'max-content' }}>
                            <Column title="Category" dataIndex="category" key="category" />
                            <Column title="Request" dataIndex="request" key="request" />
                            <Column title="Match" dataIndex="match" key="match" />
                        </Table>
                        <Button type="primary" onClick={onClose}>Close</Button>
                    </Flex>
                </Drawer>
            }
        </div>

    )
}

