import '../../assets/scss/ReleaseNotes.scss';

import { Card, Flex, Result, Select } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import { Content } from 'antd/es/layout/layout';
import LoadingSkeleton from '../Layout/LoadingSkeleton';
import Markdown from 'react-markdown';
import MotionBox from '../Layout/Animated/MotionBox';
import { PageHeader } from '../Layout/header/PageHeader';
import { SecureWithLogin } from '../../utils/secure';
import ShowError from '../Layout/ShowError';
import useReleaseNotes from '../../hooks/useReleaseNotes';
import { useState } from 'react';

export default function ReleaseNotes() {
    const params = useParams();
    
    const navigate = useNavigate();
    console.log(params['slug']);

    const [selectedReleaseNote, setSelectedReleaseNote] = useState<string>((params && params['slug']) ? params['slug'] : '');
    const { releaseNotes, loading, error } = useReleaseNotes();

    const getSelectOptions = (releaseNotes: any) => {
        let options: any[] = [];
        options.push({ label: 'All', value: '' });
        releaseNotes.forEach((note: any) => {
            options.push({ label: note.release, value: note.slug });
        });
        return options;
    }

    const handleSelectReleaseNote = (value: string) => {
        navigate(`/documentation/release-notes/${value}`);
        setSelectedReleaseNote(value);

    }


    return (
        <SecureWithLogin callbackPath="/releaseNotes" module='RELEASE_NOTES' role={[]} namespaceRequired={true}>
            {(!loading && error === null && releaseNotes) ?

                <MotionBox>
                    <Content>
                        <PageHeader
                            title='Release Notes'
                            subtitle='Portal'
                        />
                        <Flex vertical gap="large">
                            <Card style={{ padding: 0 }}>
                                <Flex gap="middle">
                                    <Select
                                        placeholder="Select a Release Note"
                                        options={getSelectOptions(releaseNotes)}
                                        onChange={handleSelectReleaseNote}
                                        value={selectedReleaseNote}
                                        onClear={() => handleSelectReleaseNote('')}
                                        style={{ minWidth: '150px', width: '100%' }}
                                    />

                                </Flex>
                            </Card>
                            {releaseNotes.filter((rn: any) => {
                                if (selectedReleaseNote === '') {
                                    return true;
                                }
                                return rn.slug === selectedReleaseNote
                            }).map((rn: any) => {
                                return (
                                    <Card
                                        title={rn.release}
                                        key={rn.id}
                                    >
                                        <div className="releaseNotes">
                                            <Markdown>{rn.content}</Markdown>
                                        </div>
                                    </Card>
                                )
                            })}
                        </Flex>


                    </Content>


                </MotionBox>
                :
                <>
                    {loading && <LoadingSkeleton />}
                    {error && <ShowError error={error.toString()} />}
                    {releaseNotes === null && !loading && <Result status="warning" title="There are some problems with your operation." />}
                </>
            }
        </SecureWithLogin >
    )
}
