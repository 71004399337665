import '../../assets/scss/CustomBarChart.scss';

import { Bar, BarChart, Brush, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { Card, Col, Typography } from 'antd';

import moment from "moment";

export default function CustomBarChart(props: { mode: string, stats: any, product: string, title: string, fromDate: string, toDate: string }) {
    const { stats, product, mode, title, fromDate, toDate } = props;

    const groupByDays = (fillEmptyDays: boolean) => {


        let group = stats.reduce((previous: any, current: any) => {
            previous[current.date] = [...previous[current.date] || [], current];
            return previous;
        }, {});


        const statsByDate = Object.keys(group).map((val: string) => {

            let totalJetscan = 0;
            let totalJetflow = 0;
            group[val].forEach((stat: any) => {

                totalJetscan += (stat.counters?.jetscan) ? stat.counters.jetscan : 0;
            });
            group[val].forEach((stat: any) => {
                totalJetflow += (stat.counters?.jetflow) ? stat.counters.jetflow : 0;
            });

            return {
                collection: moment(val).format('YYYY-MM-DD'),
                value: (product.toLowerCase() === 'jetscan') ? totalJetscan : totalJetflow,
            }
        });

        let formattedStats: any = [];
        if (fillEmptyDays) {
            //fill empty days
            let start = moment(fromDate);
            let end = moment(toDate);
            let diff = end.diff(start, 'days');

            for (let i = 0; i < diff + 1; i++) {
                const start = moment(fromDate);
                const targetDate = start.add(i, 'days').format('YYYY-MM-DD');
                const found = statsByDate.find((s: any) => s.collection === targetDate);
                if (!found) {
                    formattedStats.push({
                        collection: targetDate,
                        value: 0
                    });
                }
                else {
                    formattedStats.push(found);
                }

            }
        }
        else {
            //filter by product
            formattedStats = statsByDate.filter((s: any) => {
                if (s.value > 0) {
                    return true;
                }
                return false;

            }).reverse();
        }



        return formattedStats;
    }

    const groupByCollections = () => {

        let group = stats.reduce((previous: any, current: any) => {
            previous[current.collection] = [...previous[current.collection] || [], current];
            return previous;
        }, {});

        const doTotals = Object.keys(group).map((val: string) => {

            let totalJetscan = 0;
            let totalJetflow = 0;
            group[val].forEach((stat: any) => {
                totalJetscan += stat.counters.jetscan;
            });
            group[val].forEach((stat: any) => {
                totalJetflow += stat.counters.jetflow;
            });

            return {
                collection: group[val][0].collection,
                value: (product.toLowerCase() === 'jetscan') ? totalJetscan : totalJetflow,
            }
        });

        const filterEmptyStats = doTotals.filter((s: any) => {
            if (s.value > 0) {
                return true;
            }
            return false;

        });
        return filterEmptyStats;
    }

    const groupExplain = () => {
        let group = stats.reduce((previous: any, current: any) => {
            previous[current.date] = [...previous[current.date] || [], current];
            return previous;
        }, {});

        const statsByDate = Object.keys(group).map((val: string) => {
            let totalJetscan = 0;
            let totalJetflow = 0;
            group[val].forEach((stat: any) => {
                totalJetscan += (stat.explainCounters?.jetscan) ? stat.explainCounters.jetscan : 0;
            });
            group[val].forEach((stat: any) => {
                totalJetflow += (stat.explainCounters?.jetflow) ? stat.explainCounters.jetflow : 0;
            });

            return {
                collection: moment(val).format('YYYY-MM-DD'),
                value: (product.toLowerCase() === 'jetscan') ? totalJetscan : totalJetflow,
            }
        });

        //filter by product
        const filterEmptyStats = statsByDate.filter((s: any) => {
            if (s.value > 0) {
                return true;
            }
            return false;

        }).reverse();

        return filterEmptyStats;
    }

    let chartData: any = [];
    if (mode === 'date') {
        chartData = groupByDays(true);
    }
    else if (mode === 'collection') {
        chartData = groupByCollections();
    }
    else if (mode === 'explain') {
        chartData = groupExplain();
    }

    return (
        <>
            {
                (chartData.length > 0) &&
                <Col xs={24}><Card>
                    <Typography.Title level={5}>
                        {title}
                    </Typography.Title>
                    <div className="barChartContainer">
                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart
                                margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                                data={chartData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey={"collection"} />
                                <YAxis allowDecimals={false} />
                                <Tooltip />
                                {chartData.length > 1 &&
                                    <Brush dataKey="name" height={20} stroke="#82b9d6" />
                                }
                                {/* <Legend /> */}
                                <Bar type="monotone" dataKey={"value"} fill={product.toLowerCase() === 'jetscan' ? "#38bdf8" : '#f59e0b'} strokeWidth={0} />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                    </Card>
                </Col>
            }
            </>
    )
}
